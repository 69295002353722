import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Tabs, Tab, TableRow, TableCell } from '@material-ui/core';
import { TableListComp, EmptyCollection } from '../../../shared_elements';
import RoleBaseUser from './RoleBaseUser';
import Permissions from './Permissions';
import { usersHd, permissionHd, users } from '../'
const RoleDetail = ({toggleModalFn, roleDetail}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return(
    <Dialog
      open={roleDetail.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullScreen= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        {roleDetail.data.name}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
          >
            <Tab label="User" />
            <Tab label="Permission" />
          </Tabs>
          { value === 0 ?
            <TableListComp
              heads={usersHd}
              data={ roleDetail.data.users.map((item,index) =>
                <RoleBaseUser
                  item={item}
                  index={index}
                />
              )}
              noRecord={ [1,1].length ? null:
                <EmptyCollection
                  title="No Records Found"
                />
              }
            />:null
          }
          { value === 1 ?
            <div>
              <TableListComp
                heads={permissionHd}
                data={
                  <Fragment>
                    <Permissions roleType={roleDetail.data.name} />
                  </Fragment>
                }
              />
            </div>:null
          }
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>OKAY</Button>
      </DialogActions>
    </Dialog>
  )
}
export default RoleDetail;
