import Roles from './containers/Roles';
import Users from './containers/Users';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
export const settingsRoutes = [
  {
    path: '/settings/roles',
    component: pageLayoutHoc(Roles, {apps: 'Settings', layoutPhase: 2}),
    key: 'Roles',
    apps:'maintenance'
  },
  {
    path: '/settings/users',
    component: pageLayoutHoc(userRoleBaseAccessHoc(Users, ['console', 'manage_user', 'R']) , {apps: 'Settings', layoutPhase: 2}),
    key: 'Users',
    apps:'maintenance'
  },
]
