import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
import { prettyNumberParamsDB } from '../../../constants';
export default function ShopVisitHistory({item, viewDetail}){
  return(
    <TableRow hover tabIndex={-1} style={{cursor:"pointer"}} onClick={viewDetail}>
      <TableCell>{item.name ? item.name : '--' }</TableCell>
      <TableCell>{item.shop_visit_start_date ? moment(item.shop_visit_start_date).format('MMM DD, YYYY'): '--'}</TableCell>
      <TableCell>{item.shop_visit_date ? moment(item.shop_visit_date).format('MMM DD, YYYY'): '--'}</TableCell>
      <TableCell>{item.tsn? item.tsn : '--'}</TableCell>
      <TableCell>{item.csn ? item.csn :'--' }</TableCell>
      <TableCell>{item.removal_reason ? item.removal_reason: '--'}</TableCell>
      <TableCell className="status">
        {
          item.status && item.status.value==0 ?
          <div className="status-btn draft">{item.status.label ? item.status.label : '--' }</div> : null
        }
        {
          item.status && item.status.value==1 ?
          <div className="status-btn submitted">{item.status.label ? item.status.label : '--' }</div> : null
        }
        {
          item.status && item.status.value==2 ?
          <div className="status-btn in-review">{item.status.label ? item.status.label : '--' }</div> : null
        }
        {
          item.status && item.status.value==3 ?
          <div className="status-btn approved">{item.status.label ? item.status.label : '--' }</div> : null
        }
        {
          item.status && item.status.value==4 ?
          <div className="status-btn rejected">{item.status.label ? item.status.label : '--' }</div> : null
        }
        <div className="workscope-label">{item.workscope_details ? item.workscope_details : ''}</div>
      </TableCell>
      <TableCell>{item.shop_visit_facility ? item.shop_visit_facility:'--'}</TableCell>
      <TableCell style={{ whiteSpace:'nowrap'}}>{item.cost ? <NumericLabel params={prettyNumberParamsDB}>{item.cost}</NumericLabel> : '--'}</TableCell>

    </TableRow>
  )
}
