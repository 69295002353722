import React, { Component, Fragment } from "react";
import { Skeleton, Autocomplete } from "@material-ui/lab";
import { Grid, Link, TextField } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import config from "../../../config";
import techSpecsIcon from "../../../assets/images/tech_specs_icon.svg";
import { AssetSwitcher } from "../../../shared_elements";
import { assetTypeId,assetType} from "../../../constants";
import { generatePath } from "react-router-dom";
import { trackActivity } from '../../../utils/mixpanel';
export default function MaintenanceWrapper(HocComponent, extraProps = {}) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        flag: true,
      };
    }
    componentDidMount() {
      if (!this.props.allAssets.length) {
        this.props.getAllAssetListFn();
      }
    }
    onAssetChange = (asset) => {
      if(asset.slug){
          this.props.history.push(`/${asset.asset_type === 1 ? 'aircraft' : 'engine'}/${asset.slug}/maintenance/${asset.asset_type === 1 ? 'airframe' : 'engines'}`);
          trackActivity('Item Clicked',
          {
            page_title: 'Asset Switcher',
            asset_slug: asset.slug,
            asset_type: asset.asset_type
          })
      }
    };
    render() {
      const { flag } = this.state;
      const { allAssets, match,history } = this.props;
      return (
        <Fragment>
          {flag ? (
            <Fragment>
              <div
                className="maintenance-header-card"
                style={{ height: "60px", boxShadow:"7px 1px 12px #c6c6c6" }}
              >
                <Grid
                  container
                  alignItems="center"
                  style={{ height: "inherit" }}
                >
                  <Grid item xs={6}>
                    <ul className="list-inline flex-centered asset-switcher">
                      <li className="list-inline-item">
                        <Link href={`${config.domain.subDomian}assets-listing`}>
                          <ArrowBack />
                        </Link>
                      </li>
                      <li className="list-inline-item module-type">
                          {
                              allAssets && allAssets.length &&
                              <AssetSwitcher
                                assets = {allAssets.filter(asset => asset.asset_type <= 2)}
                                match = {match}
                                history = {history}
                                onAssetChange = {this.onAssetChange}
                            />
                          }
                      </li>
                      <li className="list-inline-item">
                        <Link
                          href={`${config.domain.subDomian}technical-specification/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}`}
                          underline="none"
                          target="_blank"
                          className="flex-centered"
                        >
                          <img
                            src={techSpecsIcon}
                            style={{ marginRight: "4px" }}
                            alt=""
                            width={15}
                          />
                          Technical Specification
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </div>
            </Fragment>
          ) : (
            <div
              className="maintenance-header-card"
              style={{ margin: "0 5px" }}
            >
              <Skeleton variant="rect" animation="wave" height={48} />
            </div>
          )}
          <HocComponent {...this.props} />
        </Fragment>
      );
    }
  };
}
