import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Button, TextField } from '@material-ui/core';
import { User, EditUserRole } from '../components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeletePopUp, PageLoader, EmptyCollection, ExportManu } from '../../../shared_elements';
import { SCardLoader } from '../../../shared_elements/loaders';
import CloseIcon from '@material-ui/icons/Close';
import { removeEmptyKey } from '../../../utils';
import { getAppsUsersApi, revokeUserAccessApi, onChangeUserRoleApi, getRolesApi, exportUserListApi } from '../apiServices';
import { users } from '../'
import config from '../../../config'
import { trackActivity } from '../../../utils/mixpanel';
class Users extends Component {
  constructor(props){
    super(props);
    this.state = {
      editRole:{modal:false, user:null},
      removeUser: {
        modal:false,
        user:null
      },
      search:'',
      status:{
        filterType:'active'
      },
      skeletonLoader:false,
      pageLoader:false,
      users: {
        list:[],
        active_users_count:'',
        inactive_users_count:'',
        pagination:null
      },
      roleList:[]
    }
    this.getAppsUsersApi = getAppsUsersApi.bind(this);
    this.exportUserListApi = exportUserListApi.bind(this);
    this.revokeUserAccessApi = revokeUserAccessApi.bind(this);
    this.getRolesApi = getRolesApi.bind(this);
    this.onChangeUserRoleApi = onChangeUserRoleApi.bind(this);
  }
  componentDidMount(){
    this.getAppsUsersApi(this.props, {page:1, per_page:20,filter_type:'active'}, 'pageLoader');
    this.getRolesApi(this.props, {});
    trackActivity('Page Visited', {
      page_title: 'Settings(Users)',
    })
  }
  moveNextPage = () => {
    const { users, search, status } = this.state;
    let nextPage = parseInt(users.pagination.page)+1;
    this.getAppsUsersApi(this.props, removeEmptyKey({per_page:users.pagination.per_page, page:nextPage, search: search, filter_type:status?.filterType ? status?.filterType : status?.value}), 'pageLoader');
  }
  onRemoveUser = (user) => {

  }
  handleChange = (data) => {
    const { users, status, search} = this.state
    this.setState(prevState => ({
      ...prevState,
      status:data
    }))
    this.getAppsUsersApi(this.props, removeEmptyKey({per_page:users.pagination.per_page, search: search, filter_type:data ? data.value : ''}, 'pageLoader'))
  }
  render(){
    const { pageLoader, skeletonLoader, roleList, users, removeUser, editRole, search, status } = this.state;
    console.log("users",users);
    const userStatusDropdown = [
      {label:`All (${users.active_users_count + users.inactive_users_count + users.invitation_sent_users_count})`, value:''},
      {label:`Active (${users.active_users_count})`, value:'active'},
      {label:`Deactivated (${users.inactive_users_count})`, value:'inactive'},
      {label:`Invitation sent (${users.invitation_sent_users_count ? users.invitation_sent_users_count : 0 })`,value:'invitation-sent'}
    ]
    return(
      <section className="users-section">
        <div className="users-header">
          <Grid container spacing={1} style={{borderBottom:'1px solid #e6e6e6'}}>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container spacing={2}>
                <Grid item md={1} style={{marginRight:'10px', marginTop:'5px'}}>
                  <h3>Users</h3>
                </Grid>
                <Grid item md={6}>
                  <ExportManu
                    title="Export"
                    files={[{title:'Excel', extension: 'xls', key:''}]}
                    exportReportFn={(file) => this.exportUserListApi(this.props, {...file, search:search, filter_type:status?.filterType ? status.filterType : status?.value})}
                  />
                </Grid>
              </Grid>
              <p style={{fontSize:'14px', marginBottom:'8px'}}>Contains the list of users</p>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <ul className="list-inline user-bulk-ops">
                  <li className="list-inline-item" style={{width:'200px', position:'relative', bottom:'15px'}}>
                    <Autocomplete
                      options = {userStatusDropdown}
                      getOptionLabel={option => option.label}
                      value={ status?.filterType === 'active' ? { label: `Active (${users.active_users_count})`, value: 'active' } : status}
                      onChange={(e, value) => this.handleChange(value)}
                      renderInput={params => <TextField {...params} placeholder="User Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </li>
                  <li className="list-inline-item search-fields">
                    <TextField
                      fullWidth
                      margin="none"
                      value={search}
                      onChange={(e) => this.setState({search:e.target.value})}
                      placeholder="Search user by Name"
                      InputLabelProps={{shrink: true}}
                    />
                  <span className="user-search-close"><CloseIcon onClick={() => {this.setState({search:''}); this.getAppsUsersApi(this.props, {page:1, per_page:20, search:'', filter_type:status?.filterType ? status.filterType : status?.value}, 'pageLoader');}} fontSize="small" /></span>
                  </li>
                  <li className="list-inline-item">
                    <Button type="submit" onClick={() => this.getAppsUsersApi(this.props, removeEmptyKey({page:1, per_page:20, search:search, filter_type:''}), 'pageLoader')} color="primary" variant="outlined" size="small">Search</Button>
                  </li>
                  <li className="list-inline-item">
                    <Button onClick={() => window.open(`${config.domain.subDomian}users/manage/?addUser=true`, '_blank')} color="primary" variant="contained" size="small">Add User</Button>
                  </li>
                </ul>
            </Grid>
          </Grid>
        </div>
        <InfiniteScroll
          dataLength={users.list ? users.list.length:0}
          next={() => this.moveNextPage()}
          hasMore={users.list && users.pagination ? users.list.length < users.pagination.total :false}
          loader={<h4 style={{clear:'both', textAlign:'center', padding:'10px'}}>Loading...</h4>}
          endMessage={users.list && users.list.length !== 0 ? <p style={{clear:'both', textAlign:'center', padding:'10px'}}>Yay! You have seen it all</p>:null}
        >
          <Grid container spacing={1}>
              { users.list.map((item, index) =>
                <User
                  item={item}
                  onRemoveUser={() => this.setState({removeUser:{modal:true, user:item}})}
                  onChangeRole={() => this.setState({editRole:{modal:true, role:null, user:item}})}
                />
              )}
          </Grid>
        </InfiniteScroll>
        { !pageLoader && users.list.length === 0 ? <EmptyCollection title="No records found" />:null}
        { removeUser.modal ?
          <DeletePopUp
            modal={removeUser.modal}
            toggleModalFn={() => this.setState({removeUser:{modal:false, user:null}})}
            title={'Remove User'}
            content={<h4>{`Are you sure you want to remove?`}</h4>}
            triggerEventFn={() => this.revokeUserAccessApi(this.props, removeUser.user)}
            confirmText={'Remove'}
          />:null
        }
        <EditUserRole
          roleList={roleList}
          editRole={editRole}
          onFieldChange={(value) =>
            this.setState(prevState => ({
              ...prevState,
              editRole:{
                ...prevState.editRole,
                role:value
              }
            }))
          }
          onChangeUserRole={() => this.onChangeUserRoleApi(this.props, editRole)}
          toggleModalFn={() => this.setState({editRole:{modal:false, user:null}})}
        />
        { pageLoader ? <PageLoader />:null }
      </section>
    )
  }
}
export default withSnackbar(Users);
