import React, { Component, Fragment } from 'react';
import { TableListComp, EmptyCollection } from '../../../../shared_elements';
import { ClaimList } from '../components';
import { getClaimListApi } from '../apiServices';
import { STableLoader } from '../../../../shared_elements/loaders';
export default class MaintenanceClaims extends Component {
  constructor(props){
    super(props);
    this.state = {
      claims: [],
      skeletonLoader:false
    }
    this.getClaimListApi = getClaimListApi.bind(this);
  }
  componentDidMount(){
    this.getClaimListApi(this.props);
  }
  render(){

    const { claims, skeletonLoader } = this.state;
    return(
      <div className="maintenance-module-section">
        <div className="maintenance-claims">
          <div className="maintenance-heads">
            <h3>Maintenance</h3>
            <p>Section holds Maintenance claims of all the major assemblies of the asset.</p>
          </div>
          { skeletonLoader ? <STableLoader count={4} /> :
            <TableListComp
              heads={[
                { label: 'Event / Shop Visit', sortOption: false },
                { label: 'Submission date', sortOption: false },
                { label: 'Amount', sortOption: false },
                { label: 'Status', sortOption: false },
              ]}
              data={claims.map((item,index) =>
                <ClaimList
                  key={index}
                  item={item}
                  viewDetail={()=> this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/maintenance/${item.asset_type}/view/${item.shop_visit}`)}
                />
              )}
              noRecord={claims.length ?  null : <EmptyCollection title="No records found" />}
            />
          }
        </div>
      </div>
    )
  }
}
