import userIcon from '../../assets/images/user.svg'
import girlIcon from '../../assets/images/girlIcon.svg'
export const rolesHd = [
  { label: 'Role Type', sortOption: false },
  { label: 'User(s)', sortOption: false },
  { label: 'Action', sortOption: false, actionCell: true }
];
export const usersHd = [
  { label: 'Name', sortOption: false },
  { label: 'Email', sortOption: false },
  { label: 'Designation', sortOption: false },
];

export const permissionHd = [
  {label:'', sortOption:false},
  {label:'Create', sortOption:false},
  {label:'View', sortOption:false},
  {label:'Edit', sortOption:false},
  {label:'Delete', sortOption:false},
]

export const users = [
  {name:'Alok Anand', email:'alok.anand@acumen.aero', lastLogin:'04-07-2021', designation:'Lessor Administrator', active:true, profile_pic:userIcon},
  {name:'Ameya Gore', email:'ameya.gore@acumen.aero', lastLogin:'04-07-2021', designation:'Lessor Administrator', active:true, profile_pic:userIcon},
  {name:'Vijay Kumar', email:'vijay.kumar@acumen.aero', lastLogin:'04-07-2021', designation:'Records Administrator', active:true, profile_pic:userIcon},
  {name:'Anubhav Chaturvedi', email:'anubhav.chaturvedi@acumen.aero', lastLogin:'04-07-2021', designation:'Records Manager', active:true, profile_pic:userIcon},
  {name:'Aravind Shekar', email:'aravind.shekar@acumen.aero', lastLogin:'04-07-2021', designation:'Records Manager', active:true, profile_pic:userIcon},
  {name:'Neha Gore', email:'neha.gore@acumen.aero', lastLogin:'04-07-2021', designation:'Records Manager', active:true, profile_pic:girlIcon},
  {name:'Sayanee Das', email:'sayanee.das@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:false, profile_pic:girlIcon},
  {name:'Divya Shukla', email:'divya.shukla@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:true, profile_pic:girlIcon},
  {name:'Babu Padalingam', email:'babu.padalingam@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:true, profile_pic:userIcon},
  {name:'Mahesh Prajapat', email:'mahesh.prajapat@acumen.aero', lastLogin:'04-07-2021', designation:'Records Inspector', active:true, profile_pic:userIcon},
  {name:'Nkhil Srivastava', email:'nikhil.srivastava@acumen.aero', lastLogin:'04-07-2021', designation:'Records View (Published & Unpublished)', active:true, profile_pic:userIcon},
  {name:'Rahul Singh', email:'rahul.singh@acumen.aero', lastLogin:'04-07-2021', designation:'Records View (Published Files Only)', active:true, profile_pic:userIcon},
  {name:'Sharmila Sundarraj', email:'sharmila.sundarraj@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (with Download)', active:true, profile_pic:girlIcon},
  {name:'Vivek Dayashankar', email:'vivek.dayashankar@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (without Download)', active:true, profile_pic:userIcon},
  {name:'Shabeeb K', email:'shabeeb.kaipanthody@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (without Download)', active:true, profile_pic:userIcon},
  {name:'Tejas Christopher', email:'tejas.christopher@acumen.aero', lastLogin:'04-07-2021', designation:'Records Custom Access (without Download)', active:true, profile_pic:userIcon},
]
