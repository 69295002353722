export const erroCodeLLP = {
  cycles_remaining:{
    0:'',
    1:'Please enter Cycles Remaining'
  },
  cycles_remainingObj:{
    required:true
  },
  list_price:{
    0:'',
    1:'Please enter Price'
  },
  list_priceObj:{
    required:true
  }
}
export const LLPHeads = [
  { label: 'Module', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Part Number', sortOption: false },
  { label: 'Cycles Max Limit', sortOption: false },
  { label: 'Cycles Remaining', sortOption: false },
  { label: 'Price', sortOption: false },
  { label: 'Action', sortOption: false }
]
