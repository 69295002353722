
import AirframeAssembly from './airframe_assembly/containers'
import AirframeAssemblyView from './airframe_assembly/containers/View'
import ApuAssembly from './apu_assembly/containers'
import ApuAssemblyView from './apu_assembly/containers/View'
import LgAssembly from './lg_assembly/containers'
import LgAssemblyView from './lg_assembly/containers/View'
import EngineAssembly from './engine_assembly/containers'
import EngineAssemblyView from './engine_assembly/containers/View'
import MaintenanceClaims from './claims/containers'

import MaintenanceWrapper from './Elements/MaintenaceWrapper';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
export const maintenanceRoutes = [
  {
    path: '/:assetType/:assetSlug/maintenance/claims',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(MaintenanceClaims),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'MaintenanceClaims'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/airframe',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(AirframeAssembly),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'AirframeAssembly'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/airframe/view/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(AirframeAssemblyView),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'AirframeAssemblyView'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/apu',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(ApuAssembly),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'ApuAssembly'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/apu/view/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(ApuAssemblyView),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'ApuAssemblyView'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/landing-gears',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(LgAssembly),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'LgAssembly'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/landing-gears/view/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(LgAssemblyView),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'LgAssemblyView'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/engines',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(EngineAssembly),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'EngineAssembly'
  },
  {
    path: '/:assetType/:assetSlug/maintenance/engine/view/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(MaintenanceWrapper(EngineAssemblyView),['maintenance', 'event_history','R']), {apps:'Maintenance', layoutPhase: 2}),
    key: 'EngineAssemblyView'
  }
]
