import React, { Fragment, Component } from 'react';
import { isMobile } from 'react-device-detect';
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, setGlobalCookie } from '../utils';
import { DesktopHeader, MobileHeader, DesktopSideNav, MntSideNav, MobileSecMenu, AccessDenied, PageLoader } from '../shared_elements';
import { connect } from 'react-redux';
import config from '../config';
import * as actions from '../shared_elements/actions'
import { getAllAssetListAc } from '../shared_elements/actionCreators'
window.addEventListener('visibilitychange', checkLogin)
function checkLogin(){
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
    if(!window.location.href.includes('login') && !window.location.href.includes('select-lessor')){
        setGlobalCookie('redirectURIRecords', window.location.href, 3)
    }
      if(lessorAccess === undefined || lessorAccess === null){
          localStorage.clear();
          if(!location.includes('login') && !location.includes('shared-data') && !location.includes('records/preview')){
             window.location.reload()
          }
        }else{
          if(config.api.networkInterface.includes(lessorAccess.environment)){
            if(!getLocalStorageInfo()){
                authorizeAutoLogin({lessor_id:lessorAccess.id})
             }else if(!location.includes('select-lessor') && !location.includes('otp')){
               if(lessorAccess.id !== userInfo.defaultLessor.id || getLocalStorageInfo().access !== lessorAccess.access){
                 authorizeAutoLogin({lessor_id:lessorAccess.id})
               }else{
                 //window.location.assign('/')
               }
             }
          }else {
            if(userInfo && userInfo.defaultLessor === undefined){
              // window.location.assign("/select-lessor");
            }
          }
        }
       // getLessorsList()
  }

}
export default function PageLayout(HocComponent, extraProps = {}) {
  class Layout extends Component {
    state = {
      filterAssets: [
        {
          id: 1,
        },
      ],
    };
    layoutMarginProps = () => {
      if (extraProps.layoutPhase) {
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return "50px 0 0 0";
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return "0px 0 0 70px";
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return "0 0 0 250px";
          default:
            return "0";
        }
      } else {
        return "0";
      }
    };
    componentDidMount(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
      if(config.api.networkInterface.includes(lessorAccess.environment)){
        if(!getLocalStorageInfo()){
            authorizeAutoLogin({lessor_id:lessorAccess.id})
         }else if(lessorAccess.id !== userInfo.defaultLessor.id){
             authorizeAutoLogin({lessor_id:lessorAccess.id})
            }
      }
    }
    render() {
      if(getLocalStorageInfo()){
        if (getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance) {
          return (
            <section>
              <Fragment>
                {isMobile ? (
                  <MobileHeader />
                ) : (
                  <Fragment>
                    <DesktopHeader />
                    <DesktopSideNav />
                  </Fragment>
                )}
              </Fragment>
              {isMobile ? (
                <MobileSecMenu extraProps={extraProps} />
              ) : extraProps.layoutPhase === 2 ? (
                <div>
                  <div className="secondary-sidenav">
                    <div style={{ padding: "47px 0" }}>
                      <MntSideNav />
                    </div>
                  </div>
                </div>
              ) : null}
              <main
                style={{
                  margin: isMobile ? "10px 0 0 0" : this.layoutMarginProps(),
                  padding: isMobile
                    ? "15px 15px 50px 15px"
                    : "20px 20px 58px 20px",background:"white",
                }}
              >
                <div>
                  <HocComponent {...this.props} />
                </div>
              </main>
            </section>
          );
        } else {
          return (
            <Fragment>
              {isMobile ? (
                <MobileHeader />
              ) : (
                <Fragment>
                  <DesktopHeader />
                  <DesktopSideNav />
                </Fragment>
              )}
              <AccessDenied/>
            </Fragment>
          );
        }
      }else {
        return <PageLoader />
      }
    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
    allAssets: state.shareReducer.allAssets,
  });
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      getAllAssetListFn: () => dispatch(getAllAssetListAc()),
      toggleTheAppSwitcher: (flag) =>
        dispatch({ type: actions.APPS_SWITCHER, payload: flag }),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(Layout);
}
