import React, { Component, Fragment } from 'react';
import { Paper, Tabs, Tab, Button, Grid } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import { withSnackbar } from 'notistack';
import { ShopVisitCard, ClaimCard, ClaimPayoutCard, EditClaimPayout, AddEditClaim, AddEditShopVisit, RejectionDialog, ShopVisitTitle } from '../../Elements';
import { TableListComp, EmptyCollection, WorksScopeTable } from '../../../../shared_elements';
import { LLPItem, AddEngineLLP } from '../components';
import { checkApiStatus, permissionCheckFn } from '../../../../utils';
import { getEngineLLPsApi, toggleEngineShopVisitStatusApi } from '../apiServices';
import { getAssemblyShopVisitApi, getAssemblyClaimListApi, getAssemblyEventsApi, addEditAssemblyShopVisitApi, addEditAssemblyClaimApi, toggleAssemblyClaimStatusApi } from '../../apiServices';
import { PageLoader } from '../../../../shared_elements';
import { LLPHeads } from '..';
import { trackActivity } from '../../../../utils/mixpanel';
class EngineAssemblyView extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex: 0,
      event: {},
      claims: [],
      llps:[],
      claim: {
        claim_amount: '',
        description: '',
        remarks: '',
        submission_date: null,
        shop_visit_id: this.props.match.params.id
      },
      pageLoader:false
    }
    this.getAssemblyEventsApi = getAssemblyEventsApi.bind(this)
    this.getAssemblyClaimListApi = getAssemblyClaimListApi.bind(this);
    this.getAssemblyShopVisitApi = getAssemblyShopVisitApi.bind(this);
    this.toggleAssemblyClaimStatusApi = toggleAssemblyClaimStatusApi.bind(this);
    this.toggleEngineShopVisitStatusApi = toggleEngineShopVisitStatusApi.bind(this);
    this.addEditAssemblyShopVisitApi = addEditAssemblyShopVisitApi.bind(this);
    this.addEditAssemblyClaimApi = addEditAssemblyClaimApi.bind(this);
    this.getEngineLLPsApi = getEngineLLPsApi.bind(this);
  }
  componentDidMount(){
    this.getAssemblyShopVisitApi(this.props, 'engine');
    this.getAssemblyEventsApi(this.props, 'engine');
    this.getAssemblyClaimListApi(this.props);
    this.getEngineLLPsApi(this.props);
  }
  addWorkscopeModuleFn = () => {
    this.setState(prevState => ({
      ...prevState,
      event:{
        ...prevState.event,
        workscope_modules: [...prevState.event.workscope_modules, {
          name: '',
          subModule:[
            {
              name:'',
              items:[
                {
                  moduleNo: '',
                  moduleDescription: '',
                  initialLevel: '',
                  finalLevel:''
                }
              ]
            }
          ]
        }]
      }
    }))
  }
  addSBWorkscopeModuleFn = (arrayIndex) => {
    this.setState(prevState => ({
      ...prevState,
      event :{
        ...prevState.event,
        workscope_modules: [...prevState.event.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
              ...majorMod, subModule: [...majorMod.subModule, {
                name:'',
                items:[{
                  moduleNo: '',
                  moduleDescription: '',
                  initialLevel: '',
                  finalLevel:''
                }]
              }]
            }:majorMod)
      }
    }))
  }
  addWorkScopeItem = (arrayIndex, innerIndex) => {
    this.setState(prevState => ({
      ...prevState,
      event :{
        ...prevState.event,
        workscope_modules:[...prevState.event.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
              ...majorMod, subModule: majorMod.subModule.map((sb, childIndex) => childIndex === innerIndex ? {...sb, items:[...sb.items, {
                moduleNo: '',
                moduleDescription: '',
                initialLevel: '',
                finalLevel:''
              }
              ]} : sb)
      }:majorMod)
      }
    }))
  }
  updateWorkScope = (event, keyParam, data, type, arrayIndex, innerArrayIndex, lastArrayIndex) => {
    switch (type) {
      case 'majorModule':
        this.setState(prevState => ({
          ...prevState,
          event : {
            ...prevState.event,
            workscope_modules: [...prevState.event.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
              ...majorMod,
              [keyParam]:data
            } : majorMod)
          }
        }))
        break;
      case 'childModule':
        this.setState(prevState => ({
          ...prevState,
          event :{
            ...prevState.event,
            workscope_modules: [...prevState.event.workscope_modules].map((majorMod,index) => index == arrayIndex ? {
              ...majorMod,
              subModule:[...majorMod.subModule].map((childModule,childIndex) => childIndex === innerArrayIndex ? {
                ...childModule,
                [keyParam]:data
              } :childModule)
            } : majorMod)
          }
        }))
        break;
      case 'itemModule':
        this.setState(prevState => ({
          ...prevState,
          event:{
            ...prevState.event,
            workscope_modules: [...prevState.event.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
              ...majorMod,
              subModule: [...majorMod.subModule].map((childModule,childIndex) => childIndex === innerArrayIndex ? {
                ...childModule,
                items: [...childModule.items].map((itemData,itemIndex) => itemIndex === lastArrayIndex ? {
                  ...itemData,
                  [keyParam]:data
                } :itemData)
              } :childModule)
            } :majorMod)
          }
        }))
        break;
      default:
    }
  }
  delWorkScopeItem = (arrayIndex, innerArrayIndex, lastArrayIndex) => {
    const workScopes = Object.assign([], this.state.event.workscope_modules );
    if(workScopes[arrayIndex].subModule.length === 1 && workScopes[arrayIndex].subModule[innerArrayIndex].items.length === 1){
      workScopes.splice(arrayIndex,1);
      this.setState(prevState => ({
        ...prevState,
        event:{
          ...prevState.event,
          workscope_modules: workScopes
        }
      }))
    }else if (workScopes[arrayIndex].subModule[innerArrayIndex].items.length==1) {
      workScopes[arrayIndex].subModule.splice(innerArrayIndex,1);
      this.setState(prevState => ({
        ...prevState,
        event:{
          ...prevState.event,
          workscope_modules: workScopes
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        event:{
          ...prevState.event,
          workscope_modules: [...prevState.event.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
            ...majorMod,
            subModule: majorMod.subModule.map((sb, childIndex) => {
              let subLength=[...sb.items];
              return childIndex == innerArrayIndex ? {...sb, ...sb.items.splice(lastArrayIndex,1)} :sb;
            })
          } : majorMod)
        }
      }))
    }
  }
  addEditWorkscope = () => {
    const { event } = this.state
    let data = JSON.parse(JSON.stringify(event));
    this.addEditAssemblyShopVisitApi(this.props, event, 'engine')
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
        trackActivity('Item Added / Edited', {
            page_type: 'Engine Workscope',
            asset_slug: this.props.match.params.assetSlug,
            asset_type: this.props.match.params.assetType,
            assembly_type: this.props.assemblyType,
          })
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })
  }
  handleChange = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  render(){
    const { tabIndex, event, claims, llps, claim, mntEvents, pageLoader } = this.state;
    return(
      <div className="maintenance-module-section">
        <Tabs
          value={tabIndex}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="normal"
          aria-label="full width tabs example"
        >
          <Tab label="Shop Visit"
            onClick={() => trackActivity('Tab Clicked',
              {
                page_title: 'Engine LLP Tabs',
                asset_slug: this.props.match.params.assetSlug,
                asset_type: this.props.match.params.assetType,
                assembly_type: this.props.assemblyType,
                tab_name: 'Shop Visit'
              })}
            />
          <Tab label="LLP" onClick={() => trackActivity('Tab Clicked',
            {
              page_title: 'Engine LLP Tabs',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
              tab_name: 'LLP'
            })}
          />
          <Tab label="Workscope" onClick={() => trackActivity('Tab Clicked',
            {
              page_title: 'Engine LLP Tabs',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
              tab_name: 'Workscope'
            })}
          />
        </Tabs>
        { tabIndex == 0 ?
          <Fragment>
            { pageLoader ? <PageLoader /> :
              <Fragment>
                <ShopVisitTitle
                  event={event}
                  assemblyType="engine"
                  goBack={()=> this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/maintenance/engines`)}
                />
              {Object.keys(event).length ?
                <ShopVisitCard
                  item={event}
                  mntEvents={mntEvents}
                  assemblyType="engine"
                  changeSVStatus={(data) => this.toggleEngineShopVisitStatusApi(this.props, data)}
                  getResponseBack={() => this.getAssemblyShopVisitApi(this.props, 'engine')}
                  rejectionFn={(data) =>  this.addEditAssemblyShopVisitApi(this.props, data,  'engine')}
                  buttonText="Shop Visit"
                  eventId={event.event_id}
                  eventName={event.name}
                /> : null
              }
              </Fragment>
            }
            { event.status && event.status.value === 3 && permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'R'}) ?
              <Fragment>
                { claims.length ?
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <h4 class="mr-claim">MR Claim</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'C'}) ?
                        <AddEditClaim
                          buttonText='Add Claim'
                          shopVisitId={parseInt(this.props.match.params.id)}
                          mode="Add"
                          claimData={claim}
                          heading='Add Claim'
                          getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                        />:null
                      }
                      
                    </Grid>
                  </Grid> :
                  <Grid container spacing={1}>
                    {permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'C'}) ?
                      <Grid item xs={12}>
                        <div className="no-records-found">
                          <div className="inner-wrapper">
                            <p className="claim-text">No Claim(s) Added. Please Proceed with Adding the Claims.</p>
                            <AddEditClaim
                              buttonText='Add Claim'
                              shopVisitId={parseInt(this.props.match.params.id)}
                              mode="Add"
                              claimData={claim}
                              heading='Add Claim'
                              size="large"
                              variant="outlined"
                              getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                            />
                          </div>
                        </div>
                      </Grid>:null
                    }
                  </Grid>
                }
                { claims.map((claim, index) =>
                  <Paper  key={index} elevation={3} className="mnt-event-card">
                    <ClaimCard
                      item={claim}
                      assemblyType="engine"
                      changeClaimStatus={(data) => this.toggleAssemblyClaimStatusApi(this.props, claim.id, data)}
                      mntEvents={mntEvents}
                      getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                      rejectionFn={(data)=> this.addEditAssemblyClaimApi(this.props, data, 'engine')}
                    />
                    { claim.status.value == 3 ?
                      <ClaimPayoutCard
                        item={claim}
                        assemblyType="Shop Visit"
                        changeClaimStatus={(data) => this.toggleAssemblyClaimStatusApi(this.props, claim.id, data)}
                        getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                      />:null
                    }
                  </Paper>
                )}
              </Fragment>:null
            }
          </Fragment> : null
        }
        { tabIndex == 1 ?
          <Fragment>
            {permissionCheckFn( {secondaryKey:'event_history', keyIndex:'U'}) ?
              <AddEngineLLP eventId={event.asset_id} getResponseBack={() => this.getEngineLLPsApi(this.props)} />:null
            }
            <TableListComp
              heads={LLPHeads}
              data={llps.map((item,index) => <LLPItem key={index} item={item} getResponseBack={() => this.getEngineLLPsApi(this.props)} />)}
              noRecord={llps.length ? null : <EmptyCollection title="No records found" /> }
            />
        </Fragment> : null
        }
        { tabIndex == 2 ?
          <Fragment>
          { permissionCheckFn( {secondaryKey:'event_history', keyIndex:'U'}) ?
            <div className="text-left" style={{position:'absolute'}}>
              <Button
                variant = "contained"
                color="primary"
                size="medium"
                disableElevation={true}
                onClick={()=> {this.addEditWorkscope();trackActivity('Item Clicked', { page_title: 'Engine Workscope', item_type: 'Save', assembly_type: this.props.assemblyType })} }
              >Save Changes</Button>
            </div> : null
          }
            <WorksScopeTable
              workScopes={event.workscope_modules}
              addWorkscopeModuleFn={this.addWorkscopeModuleFn}
              addSBWorkscopeModuleFn={this.addSBWorkscopeModuleFn}
              addWorkScopeItem={this.addWorkScopeItem}
              updateWorkScope={this.updateWorkScope}
              delWorkScopeItem={this.delWorkScopeItem}
              flag={permissionCheckFn( {secondaryKey:'event_history', keyIndex:'U'})}
            />
        </Fragment> : null
        }
      </div>
    )
  }
}
export default withSnackbar(EngineAssemblyView);
