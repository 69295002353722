import React, {useState} from 'react';
import { Provider } from 'react-redux';
import { withOrientationChange } from 'react-device-detect'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Offline } from "react-detect-offline";
import { SnackbarProvider } from 'notistack';
import { createMuiTheme, ThemeProvider, Zoom, Fab, useScrollTrigger, makeStyles } from '@material-ui/core';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import configureStore from './store/configureStore';
import { maintenanceRoutes } from './application/maintenance/appRoutes';
import { authRoutes } from './application/auth/authRoutes';
import { assetRoutes } from './application/home/assetRoutes';
import {dashboardRoutes} from './application/dashboard/dashboardRoutes';
import { settingsRoutes } from './application/settings/settingsRoutes';
import { reportRoutes } from './application/reports/reportRoutes';
import PrivateRoute from './hocs/PrivateRoute';
import {PageNotFound} from './shared_elements';
import MaintenanceModal from './shared_elements/components/MaintenanceModal';
const store = configureStore();
const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));
function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
const App = ({isLandscape, isPortrait}) => {
  const [maintenanceModal, setMaintenanceModal ] = useState(false)
  return (
    <Provider store={store}>
      <div id="back-to-top-anchor" />
      <SnackbarProvider maxSnack={3}
      >
        <Offline><span style={{position:'fixed', top:'0', left:'0', right:'0', margin:'0 auto', width: '300px', background:'yellow', zIndex:999999, textAlign:'center'}}>You are offline currently</span></Offline>
        <Router>
          <Switch>
            { authRoutes.map(({path, component, key}, index) =>
              <Route exact={true} path={path} component={component} key={key}/>
            )}
            {[...dashboardRoutes, ...maintenanceRoutes, ...assetRoutes, ...settingsRoutes, ...reportRoutes].map(({path, component, key}, index)  =>
              <PrivateRoute exact path={path} component={component} key={key}/>
            )}
            <Route path="*" component={PageNotFound}/>
          </Switch>
        </Router>
      </SnackbarProvider>
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <MaintenanceModal maintenanceModal={maintenanceModal} onCloseMaintenanceModal={() => setMaintenanceModal(false) }  />
    </Provider>
  );
}
export default withOrientationChange(App)
