import config from '../../config';
import { globalPostService } from '../../utils/globalApiServices';
import { checkApiStatus, setGlobalCookie, checkEnvironment } from '../../utils';
import { trackLogin } from '../../utils/mixpanel'
import mixpanel from 'mixpanel-browser';
// API Call
export function loginApi(props, data={}) {
  this.setState({formSubmitLoader: true});
  globalPostService('api/login/', data)
  .then(response => {
    this.setState({formSubmitLoader: false});
    if(checkApiStatus(response)){
      // mixpanel.track("userLogin",{
      //   "email":data.email
      // })
      if(response.data.data.lessor_list.length){
        let expireAfter = new Date();
        expireAfter.setDate(expireAfter.getDate() + 3);
        let cookieData = JSON.stringify({access:response.data.data.access, environment: checkEnvironment()})
        setGlobalCookie("lessorAccess", cookieData, 3)
        localStorage.setItem('userInfo', JSON.stringify(response.data.data));
        if(response.data.data.lessor_list.length > 1){
          props.history.push('/select-lessor');
        }else{
          this.lessorUpdateApi(props, {lessor_id: response.data.data.lessor_list[0].id})
        }
      }else{

      }
      this.props.enqueueSnackbar('Login Successful.', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else if(response.data.statusCode == 1012){
            this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            props.history.push('/otp-verification/' + response.data.data.token)
            localStorage.setItem('userEmail', data.email)
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function lessorUpdateApi(props, data={}, reloadWindow = false){
  this.setState({formSubmitLoader: true});
  globalPostService(`api/user-authorization/`, data)
  .then(response => {
    this.setState({formSubmitLoader: false});
    if(checkApiStatus(response)){
      let cookieData = JSON.stringify({access:response.data.data.lessor_access_token, id:response.data.data.current_lessor.id, refresh:'', environment: checkEnvironment()})
      let cookieName = JSON.stringify({name:response.data.data.user.name, designation:response.data.data.user.designation})
      setGlobalCookie("lessorAccess", cookieData, 3)
      setGlobalCookie('userName', cookieName, 3)
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      userInfo = {
        ...userInfo,
        access: response.data.data.lessor_access_token,
        defaultLessor: response.data.data.current_lessor,
        user: response.data.data.user,
      }
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      props.history.push('/dashboard');
      trackLogin(userInfo , () => {
        if(reloadWindow){
          setTimeout(() => window.location.reload(), 500)
        }
      })
    }else {
    }
  })
}
export function signUpApi(props={}, data){
  globalPostService('api/sign-up/',data)
  .then(response => {
    if(checkApiStatus(response)){
      props.history.push('/otp-verification/' + response.data.data.token)
      localStorage.setItem('userEmail', data.email);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function OTPVerifyApi(props={}, data){
  globalPostService('api/pass-verify-otp/',data)
  .then(response => {
    if(checkApiStatus(response)){
      localStorage.clear()
      props.history.push('/login');
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function OTPResendApi(props={}, data){
  globalPostService('api/pass-resend-otp/',data)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function forgotApi(props, data={}){
  this.setState({formSubmitLoader: true});
  globalPostService(`api/password/reset/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar('Email Sent Successfully.', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      props.history.push('/login');
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({formSubmitLoader: false});
  })
}
export function resetPasswordApi(props, data={}){
  this.setState({formSubmitLoader:true});
  globalPostService(`api/password/reset-confirm/`, {...data, token: props.match.params.token.split('_')[1], uSlug: props.match.params.token.split('_')[0]})
  .then(response => {
    if(checkApiStatus(response)){
      props.history.push('/login');
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.setState({formSubmitLoader:false});
  })
}
// State Update Related Functions
export function updateAuthInfoState(value, key){
  this.setState(prevState => ({
      ...prevState,
      authInfo: {
        ...prevState.authInfo,
        [key]: value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
  }))
}
