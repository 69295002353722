import React, { Fragment, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TableRow, TableCell } from '@material-ui/core';
import { TableListComp } from '../../../../shared_elements';
const WorkScopeLLP = ({llps}) => {
  const [open, setOpen] = React.useState(false);
  return(
    <Fragment>
      <Dialog
        open={open}
        onClose={()=>{this.setState({modal:false, error:{},claim:{}})}}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          LLP
        </DialogTitle>
        <DialogContent dividers={true}>
          <TableListComp
            heads={[{label:'LLP Type', sortOption:false}, {label:'Cycle Remaining', sortOption:false}, {label:'Cost', sortOption:false}]}
            data={llps.map((item,index) =>
              <TableRow>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.remaining_cycles}</TableCell>
                <TableCell>{item.listprice}</TableCell>
              </TableRow>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> this.setState({ modal:false, error:{},claim:{}})} color="primary">Okay</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default WorkScopeLLP;
