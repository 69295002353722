import React, { Fragment }  from 'react';
import moment from 'moment';
import { TableCell, TableRow } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { TableListComp } from '../../../../shared_elements';
import {getCurrencyFormatLong,getNumberDecimalFormat } from '../../../../utils';
import { displayDateFormatShort } from '../../../../constants';
const AirframeUpcomingEvents = ({upcomingEvents}) => {
  let heads = upcomingEvents.map((item) => { return {label:item.name, sortOption:false}})
  return(
    <div>
      <TableListComp
        heads={[{label:'Maintenance Event', sortOption:false}, ...heads]}
        data={
          <Fragment>
            <TableRow hover tabIndex={-1}>
              <TableCell>Next Event Date</TableCell>
              { upcomingEvents.map((event, index) =>
                <TableCell>{event.eventDate? moment(event.eventDate).format(displayDateFormatShort):'--'}</TableCell>
              )}
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell>Next Event TSN(expected)</TableCell>
              { upcomingEvents.map((event, index) =>
                <TableCell>{event.expected_tsn? <NumericLabel params={getNumberDecimalFormat()}>{event.expected_tsn}</NumericLabel>:'--'}</TableCell>
              )}
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell>Next Event CSN(expected)</TableCell>
              { upcomingEvents.map((event, index) =>
                <TableCell>{event.expected_csn?Math.floor(event.expected_csn):'--'}</TableCell>
              )}
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell>Escalated Event cost(expected)</TableCell>
              { upcomingEvents.map((event, index) =>
                <TableCell>{event.eventCost?<NumericLabel params={getCurrencyFormatLong()}>{event.eventCost}</NumericLabel>:'--'}</TableCell>
              )}
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell>Escalated MR cost(expected)</TableCell>
              { upcomingEvents.map((event, index) =>
                <TableCell>{event.mr? <NumericLabel params={getCurrencyFormatLong()}>{event.mr}</NumericLabel> :'--'}</TableCell>
              )}
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell>Fund Balance(expected)</TableCell>
              { upcomingEvents.map((event, index) =>
                <TableCell>{event.fundCollected? <NumericLabel params={getCurrencyFormatLong()}>{event.fundCollected}</NumericLabel>:'--'}</TableCell>
              )}
            </TableRow>
          </Fragment>
        }
      />
    </div>
  )
}
export default AirframeUpcomingEvents;
