import {Component} from "react";
import ExportIcon from "../../../assets/images/export_icon.svg";
import {Grid} from "@material-ui/core";
import GenericUpcomingEvents from "./GenericUpcomingEvents";
import {exportUpcomingEvents} from "../apiServices";
import {PageLoader} from "../../../shared_elements"

export default class UpcomingEvents extends Component{
    constructor(props){
      super(props);
      this.state = {
        pageLoaderFlag : false
      }
      this.exportUpcomingEvents = exportUpcomingEvents.bind(this);
    }
    render(){
      const {pageLoaderFlag} = this.state;
      const {upcomingEventsData,upcomingEventsFlag,exportUpcomingEvents} = this.props;
      return (
        <section className="upcoming-events" style={{display:"none"}}>
        { pageLoaderFlag ? <PageLoader/> : null }
          <h6>
            <span>Upcoming Events</span>
            <span className="export-btn" onClick={this.exportUpcomingEvents}>
              <img width="12" src={ExportIcon} alt="export" />
              <span>EXPORT</span>
            </span>
          </h6>
          {
            upcomingEventsFlag
            ?
            <Grid container spacing={2}>
              <GenericUpcomingEvents
                title="Airframe"
                type="Event"
                data={upcomingEventsData.airframe}
              />
              <GenericUpcomingEvents
                title="Engine"
                type="Shop Visit"
                data={upcomingEventsData.engine}
              />
              <GenericUpcomingEvents
                title="APU"
                type="Shop Visit"
                data={upcomingEventsData.apu}
              />
              <GenericUpcomingEvents
                title="Landing Gear"
                type="Shop Visit"
                data={upcomingEventsData.lg}
              />
            </Grid>
            :
            <div>
              <p className="loader">Loading...</p>
            </div>
          }
        </section>
      );
    }
}
