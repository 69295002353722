import React, { Fragment } from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
const Permissions = ({roleType}) => {
  return(
    <Fragment>
      <TableRow hover tabIndex={-1}>
      <TableCell><h4>Claim</h4></TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators', 'Maintenance View', 'Maintenance Manager'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators', 'Maintenance View', 'Maintenance Manager'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators', 'Maintenance Manager'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators', 'Maintenance Manager'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow hover tabIndex={-1}>
      <TableCell><h4>Shop Visits / Events</h4></TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators', 'Maintenance View', 'Maintenance Manager'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators', 'Maintenance View', 'Maintenance Manager'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators', 'Maintenance Manager'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators', 'Maintenance Manager'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ? 'Access':'Access Restricted'} arrow>
            {['Lessor Administrators', 'Maintenance Administrators'].includes(roleType) ?
              <CheckCircleIcon style={{color:'#006400'}} />:<CancelIcon style={{color:'#8B0000'}} />
            }
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
export default Permissions;
