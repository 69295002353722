import React, {Component, Fragment} from 'react';
import { withRouter} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { withSnackbar } from 'notistack';
import { errorCodeClaimPayout } from '../';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
import { addEditAssemblyClaimApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
class EditClaimPayout extends Component{
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      claim:{},
      error:{},
    }
    this.addEditAssemblyClaimApi = addEditAssemblyClaimApi.bind(this)
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      claim: {
        ...prevState.claim,
        [keyParam]: data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState =>({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditClaim = () => {
    const { claim } = this.state
    let validationInputs = {
      amount_paid:errorCodeClaimPayout['amount_paid'][fieldValidation({...errorCodeClaimPayout['amount_paidObj'], fieldval:claim.amount_paid})],
      reference_number:errorCodeClaimPayout['reference_number'][fieldValidation({...errorCodeClaimPayout['reference_numberObj'], fieldval:claim.reference_number})]
    }
    if(Object.keys(validationInputs).every((k)=> validationInputs[k] == '')){
      this.addEditAssemblyClaimApi(this.props, claim)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant:"success", anchorOrigin:{vertical:"top", horizontal:"right"}})
          this.setState({ modal: false, error:{}, claim:{}})
          this.props.getResponseBack()
          trackActivity('Item Added / Edited', {
              page_type: 'Claims Payout',
              page_title: 'Claims',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
            })
        }else {
          this.props.enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{vertical:'top',horizontal:'right'}})
        }
      })
    }else {
      this.setState({
        error: validationInputs
      })
    }
   }
  render(){
    const {addEditClaim, claimData} = this.props
    const {modal, claim, error} = this.state
    return (
      <Fragment>
        <EditIcon onClick={()=> this.setState({modal:true, claim:claimData }, () => trackActivity('Item Clicked', { page_title: 'Claims', item_type: 'edit', assembly_type: this.props.assemblyType }))} color="primary" />
        <Dialog
          open={modal}
          onClose={()=> this.setState({modal:false, claim:{}, error:{}})}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit Payout Detail
          </DialogTitle>
          <DialogContent dividers={true}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  id="amount_paid"
                  label="Payout Amount"
                  fullWidth
                  margin="normal"
                  value={claim.amount_paid ? claim.amount_paid:''}
                  onChange={(e, value) =>  { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'amount_paid', e.target.value): e.preventDefault()}}
                  error={error.amount_paid ? true:false }
                  helperText={error.amount_paid? error.amount_paid : ''}
                  InputLabelProps={{shrink: true}}
                  onFocus={()=> this.updateErrorField('amount_paid', '')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="reference_number"
                  label="Reference Number"
                  fullWidth
                  margin="normal"
                  value={claim.reference_number ? claim.reference_number:''}
                  onChange={(e, value) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'reference_number', e.target.value) : e.preventDefault()}}
                  error={error.reference_number ? true:false }
                  helperText={error.reference_number ? error.reference_number : ''}
                  InputLabelProps={{shrink: true}}
                  onFocus={()=> this.updateErrorField('reference_number', '')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="transaction_description"
                  label= {
                    <Box style={ isMobile ? {position:'relative', top:'-18px'} : {}}>
                      Transaction Description
                    </Box>
                  }
                  fullWidth
                  multiline
                  rows='4'
                  margin="normal"
                  value={claim.transaction_description ? claim.transaction_description:''}
                  onChange={(e, value) => this.onFieldChange(e, 'transaction_description', e.target.value)}
                  error={error.transaction_description ? true:false }
                  InputLabelProps={{shrink: true}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="remarks"
                  label="Remarks"
                  fullWidth
                  margin="normal"
                  multiline
                  rows="4"
                  value={claim.remarks ? claim.remarks:''}
                  onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                  error={error.remarks ? true:false }
                  InputLabelProps={{shrink: true}}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.setState({modal:false, claim:{}, error:{}}, () => trackActivity('Item Clicked', { page_title: 'Claim Payout', page_type: 'Claims', activity_type: 'Cancel' }))} color="primary">Cancel</Button>
            <Button variant="contained" onClick={this.addEditClaim } color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Fragment>

    );
  }
}
export default withSnackbar(withRouter(EditClaimPayout));
