import { useState } from 'react';
import { Grid,Paper,Accordion,AccordionSummary,AccordionDetails,Typography,Link} from '@material-ui/core';
import moment from 'moment';
import { ExpandMore } from "@material-ui/icons";
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat ,prettyNumberParamsDB } from '../../../constants';
const EventCard = ({event, index, type}) => {
  const [showdDate, setDate] = useState(true)
  return (
    <Grid item xs={12} className="event" key={index}>
      <Accordion
        onChange={() => setDate(!showdDate)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <h4>
          <Link>
          {(() => {
            if(event.asset.asset_type === 1) {
              return (
                `MSN ${event.asset.msn}`
              )
            }else if (event.asset.asset_type === 2) {
              return (
                `ESN ${event.asset.esn}`
              )
            }else if (event.asset.asset_type === 3) {
              return(
                `Serial No. ${event.asset.serial_number}`
              )
            }else if (event.asset.asset_type === '4') {
              return(
                `Serial No. ${event.asset.serial_number}`
              )
            }else {
              return (
                <div>catch all</div>
              )
            }
          })()}
          </Link>
          <span>
          {
            event.name ? " (" + event.name + ")" : ""
          }
          </span>
          {
            showdDate ?
            <p>
              Next {type} Date:{" "}
              {moment(event.eventDate).format(displayDateFormat)}
            </p> : null
          }
          { moment() > moment(event.eventDate)?
            <p className="error-msg">Shop visit is overdue! Please update historical shop visit data under Maintenance of the asset.</p>:null
          }
          {
            event.exceeding_contract?
              <p style={{color: '#DFD700', marginTop: '6px'}}>Upcoming shop visit exceeds active contract end date</p>:null
          }
        </h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="main">
            <div className="main-info">
              <ul>
              <li>
                  <h6>Last {type} Date</h6>
                  <p>{ event.lastEventDate ? moment(event.lastEventDate).format(displayDateFormat): '--' }</p>
                </li>
                <li>
                  <h6>Next {type} Date</h6>
                  <p>{ event.eventDate ? moment(event.eventDate).format(displayDateFormat): '--' }</p>
                </li>
              </ul>
            </div>
            <div className="main-other">
              <ul>
                  <li>
                    <h6>Fund Balance as of {type} Date</h6>
                    <p>{event.fundCollected ? <NumericLabel params={prettyNumberParamsDB}>{event.fundCollected}</NumericLabel>:'--'} </p>
                  </li>
                  <li>
                    <h6>{type} Cost Estimate</h6>
                    <p>{event.eventCost ? <NumericLabel params={prettyNumberParamsDB}>{event.eventCost}</NumericLabel>:'--'}</p>
                  </li>
                  <li>
                    <h6>Estimated Remaining Balance</h6>
                    <p>{event.surPlus ? <NumericLabel params={prettyNumberParamsDB}>{event.surPlus}</NumericLabel>:'--'}</p>
                  </li>
                </ul>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
export default EventCard;
