import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Container,Dialog, Grid, Tooltip, IconButton, TextField, Badge, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import UserDropDownMenu from './UserDropDownMenu';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import { lessorUpdateApi } from '../../application/auth/apiServices';
import FAQsList from './FAQsList';
import ReportAnIssue from './ReportAnIssue';
import * as actions from '../actions';
import PageLoader from './PageLoader';
import { getLocalStorageInfo, eraseGlobalCookie } from '../../utils';
import config from '../../config'
import ApplicationsAccess from './ApplicationsAccess';
import { getWhatsNewFeaturesListApi, getFaqsListApi, getHeaderCartCountApi } from '../apiServices';
class DesktopHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      formSubmitLoader:false,
      whtsNewModal:false,
      whtsNewList:[],
      faqsModal:false,
      faqsList:[],
      headerCount:{},
      lessor: null,
      appswictherModal:false
    }
    this.getWhatsNewFeaturesListApi = getWhatsNewFeaturesListApi.bind(this);
    this.getFaqsListApi = getFaqsListApi.bind(this);
    this.getHeaderCartCountApi = getHeaderCartCountApi.bind(this);
    this.lessorUpdateApi=lessorUpdateApi.bind(this)
  }
  componentDidMount(){
    this.getHeaderCartCountApi();
    if(getLocalStorageInfo()){
      let lessorValue = {id:getLocalStorageInfo().defaultLessor.id, name: getLocalStorageInfo().defaultLessor.name}
      this.setState({lessor: lessorValue})
    }
  }
  handleChange = (event, keyParam, value) => {
    this.setState({
      [keyParam]: value
    })
  }

  render(){
    const { whtsNewModal, whtsNewList, faqsModal, faqsList, headerCount, pageLoader, formSubmitLoader, lessor } = this.state;
      return(
        <Fragment>
          <div style={{height:'41px'}}></div>
          <header className="desktop-header">
            <Container maxWidth={false}  style={{padding:'0px 5px'}}>
              <Grid container  spacing={0} alignItems="center" justify="center">
                <Grid item xs={5}>
                  <ul className="list-inline app-logo-search flex-centered">
                    <li className="list-inline-item app-logo">
                      <Link to="/">
                        <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" />
                      </Link>
                    </li>
                    <li>
                      <Button
                        className="app-switcher"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={() => this.setState({appswictherModal:true})}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Maintenance Claim Management
                      </Button>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={7}>
                  <ul className="list-inline header-right-nav flex-centered">
                    <li className="list-inline-item">
                      <Tooltip title="What's New" arrow>
                        <IconButton size="small" color="primary" onClick={() => this.getWhatsNewFeaturesListApi(this.props)}>
                          <RecordVoiceOverIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </li>
                    <li className="list-inline-item">
                      <ReportAnIssue type="head" />
                    </li>
                    <li className="list-inline-item">
                      <Tooltip title="FAQs" arrow>
                        <IconButton size="small" color="primary" onClick={() => this.getFaqsListApi(this.props)}>
                          <QuestionAnswerIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </li>
                    <li className="list-inline-item">
                      <Tooltip title="Notifications" arrow>
                        <IconButton size="small" color="primary">
                          <Link onClick={() => window.open(`${config.domain.subDomian}notifications`, '_blank')}>
                            <Badge max={99} badgeContent={headerCount.notification_count? headerCount.notification_count:0} color="secondary">
                              <NotificationsActiveIcon color="primary" fontSize="small" />
                            </Badge>
                          </Link>
                        </IconButton>
                      </Tooltip>
                    </li>
                    {/* { getLocalStorageInfo().user.permission.console.cart ?
                      <li className="list-inline-item" style={{display:'none'}}>
                        <IconButton size="small" color="primary">
                          <Link to="/license/cart">
                            <Badge max={99} badgeContent={headerCount.card_count? headerCount.card_count:0} color="secondary">
                              <ShoppingCartIcon />
                            </Badge>
                          </Link>
                        </IconButton>
                      </li>:null
                    } */}
  
                    <li className="list-inline-item" style={{width:'200px'}}>
                      <Autocomplete
                        options = {getLocalStorageInfo() && getLocalStorageInfo().lessor_list ? getLocalStorageInfo().lessor_list : []}
                        clearOnBlur={false}
                        getOptionLabel={option => option.name}
                        disableClearable={true}
                        filterSelectedOptions={true}
                        onChange={(e, data) => this.lessorUpdateApi(this.props, {lessor_id:data.id}, true)}
                        value={lessor}
                        renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </li>
                    <li className="list-inline-item">
                      <UserDropDownMenu
                        logout={() => {
                          eraseGlobalCookie('lessorAccess')
                          eraseGlobalCookie('redirectURIMaintenance')
                          eraseGlobalCookie('domain')
                          eraseGlobalCookie('userName')
                          this.props.history.push('/login')
                          localStorage.clear();
                        }}
                      />
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Container>
            { whtsNewModal ?
              <WhatsNewCompleteList toggleModalFn={() => this.setState({whtsNewModal:false})} modal={whtsNewModal} whtsNewList={whtsNewList} />:null
            }
            { faqsModal ?
              <FAQsList toggleModalFn={() => this.setState({faqsModal:false})} modal={faqsModal} faqsList={faqsList} />:null
            }
          </header>
          <ReportAnIssue type="foot" />
          { this.state.appswictherModal ?
            <Dialog
              open={this.state.appswictherModal}
              onClose={() => this.setState({appswictherModal:false})}
              aria-labelledby="scroll-dialog-title"
              fullScreen={true}
              className='application-swicther-modal'
            >
              <ApplicationsAccess
                handleClose={() => this.setState({appswictherModal:false})}
              />
            </Dialog>
            : null
        }
          { pageLoader || formSubmitLoader ? <PageLoader/>:null }
        </Fragment>
      )
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // toggleTheAppSwitcher: (flag) => dispatch({type:actions.APPS_SWITCHER, payload:flag}),
  }
}
export default withSnackbar(withRouter(connect(null,mapDispatchToProps)(DesktopHeader)))
