import React, { Component } from 'react';
import { Tabs, Tab, } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';
import { TableListComp, EmptyCollection, PageLoader, LicenseFailModal } from '../../../../shared_elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import {  ShopVisitHistory, AddEditShopVisit, Claims,NoUpcomingEvents } from '../../Elements';
import { AirframeUpcomingEvents } from '../components';
import { getAssemblyEventListApi, addEditAssemblyShopVisitApi, getAssemblyEventsApi, getClaimListApi } from '../../apiServices';
import { getAssemblyUpcomingEventListApi } from '../apiServices';
import { permissionCheckFn } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { aircftEventsHd } from '../../';
class AirframeAssembly extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex: 0,
      eventHistories: [],
      upcomingEvents: [],
      skeletonLoader:true,
      pageLoader:false,
      licenseModal:false,
      licenseModalMessage:'',
      mntEvents:[],
      claims:[]
    }
    this.getAssemblyEventListApi = getAssemblyEventListApi.bind(this);
    this.getClaimListApi = getClaimListApi.bind(this);
    this.getAssemblyUpcomingEventListApi = getAssemblyUpcomingEventListApi.bind(this);
    this.getAssemblyEventsApi = getAssemblyEventsApi.bind(this);
    this.addEditAssemblyShopVisitApi = addEditAssemblyShopVisitApi.bind(this);
  }
  componentDidMount(){
    this.getAssemblyUpcomingEventListApi(this.props, 'airframe');
    this.getAssemblyEventListApi(this.props, 'airframe');
    this.getAssemblyEventsApi(this.props, 'airframe');
    this.getClaimListApi(this.props,{asset_type:'airframe'});
    trackActivity('Page Visited', {
      page_title: 'Maintenance Claims',
      asset_slug: this.props.match.params.assetSlug,
      asset_type: this.props.match.params.assetType,
    })
  }
  componentWillReceiveProps(nextProps){
    this.getAssemblyUpcomingEventListApi(nextProps, 'airframe');
    this.getAssemblyEventListApi(nextProps, 'airframe');
    this.getAssemblyEventsApi(nextProps, 'airframe');
    this.getClaimListApi(nextProps,{asset_type:'airframe'});
  }
  handleChange = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  render(){
    const { tabIndex, eventHistories, upcomingEvents, mntEvents, skeletonLoader, pageLoader, claims, licenseModal, licenseModalMessage } = this.state;
    return(
      <div className="maintenance-module-section">
        <div className="maintenance-heads">
          <h3>Airframe</h3>
          <p>Section holds Maintenance claims of Airframe.</p>
        </div>
        <Tabs
          value={tabIndex}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="full width tabs example"
        >
          <Tab label="Event History"
            onClick={() => trackActivity('Tab Clicked',
              {
                page_title: 'Maintenance Claims',
                asset_slug: this.props.match.params.assetSlug,
                asset_type: this.props.match.params.assetType,
                tab_name: 'Event History'
              })}
          />
          {permissionCheckFn( {secondaryKey:'upcoming_events', keyIndex:'R' }) ?
            <Tab label="Upcoming Events"
              onClick={() => trackActivity('Tab Clicked',
                {
                  page_title: 'Maintenance Claims',
                  asset_slug: this.props.match.params.assetSlug,
                  asset_type: this.props.match.params.assetType,
                  tab_name: 'Upcoming Events'
                })}
            />:null
          }
          {permissionCheckFn({secondaryKey:'maintenance_claims', keyIndex:'R' }) ?
            <Tab label="Claims"
              onClick={() => trackActivity('Tab Clicked',
                {
                  page_title: 'Maintenance Claims',
                  asset_slug: this.props.match.params.assetSlug,
                  asset_type: this.props.match.params.assetType,
                  tab_name: 'Claims'
                })}
            />:null
          }
        </Tabs>
        <div className="shop-visit-list" text="">
          {tabIndex === 0 ? skeletonLoader ? <STableLoader count={8} /> :
            <div style={{position:'relative'}}>
              {skeletonLoader ?
                <div style={{float:"right"}}><Skeleton  animation='wave' width={160} height={48} /></div>
                : permissionCheckFn( {secondaryKey:'event_history', keyIndex:'C' })  ?
                <AddEditShopVisit
                  assemblyType='airframe'
                  buttonText="Maintenance Event"
                  mode="Add"
                  heading='Add Maintenance Event'
                  mntEvents={mntEvents}
                  getResponseBack={() => this.getAssemblyEventListApi(this.props, 'airframe')}
                  pageType="Add Maintenance Events"
                  toggleLicenseFn={(modal, message) => this.setState({licenseModal:modal, licenseModalMessage:message})}
                /> : null
              }
                <TableListComp
                  heads={aircftEventsHd}
                  data={eventHistories.map((item,index) =>
                    <ShopVisitHistory
                      viewDetail={() => this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/maintenance/airframe/view/${item.id}`)}
                      key={index}
                      item={item}
                    />
                  )}
                  noRecord={ eventHistories.length ? null:  <EmptyCollection title="No records found" /> }
                />
            </div> : null
          }
          { tabIndex == 1 ? pageLoader ? <PageLoader /> :
            <div style={{position:'relative'}}>
              {
                upcomingEvents?.length ?
                <AirframeUpcomingEvents upcomingEvents={upcomingEvents} />
                :<NoUpcomingEvents/>
              }
            </div> : null
          }
          { tabIndex == 2 ? pageLoader ? <PageLoader /> :
            <div style={{position:'relative'}}>
              <Claims claims={claims} skeletonLoader={skeletonLoader} props={this.props} />
            </div> : null
          }
        </div>
        <LicenseFailModal licenseModalMessage={licenseModalMessage} licenseModal={licenseModal}  toggleModalFn={() =>  this.setState({licenseModal:false, licenseModalMessage:'', })} />
      </div>
    )
  }
}
export default withSnackbar(AirframeAssembly);
