import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { checkApiStatus, checkLicenseStatus,getLocalStorageInfo } from '../../../utils';
import { addNewAssetApi } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { aircraftCrud, aircraftErrorCode } from '../'
import { trackActivity } from '../../../utils/mixpanel';
import {leaseStatus} from '../../../shared_elements/components';
class AddAircraft extends Component{
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader:false,
      data:aircraftCrud,
      error:{}
    }
    this.addNewAssetApi = addNewAssetApi.bind(this);
  }

  onFieldChange = (keyParam,value) => {
    if(keyParam === 'aircraft_type'){
      if(value){
        this.props.fetchMntGrops(value.id)
      }else{
        this.onFieldChange('maintenance_event_program_group', null)
      }
    }
    this.setState(prevState => ({
      ...prevState,
      data:{
        ...prevState.data,
        [keyParam]:value
      }
    }))
  }
  onRestErrorKey = (keyParam) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [keyParam]:''
      }
    }))
  }
  onAddNewAsset = () => {
    const { data } = this.state;
    // Apply the validation Here
    let validationInputs = {};
    validationInputs = {
      ...validationInputs,
      msn:aircraftErrorCode['msn'][fieldValidation({...aircraftErrorCode['msnObj'], fieldval: data.msn})],
      aircraft_type:aircraftErrorCode['aircraft_type'][fieldValidation({...aircraftErrorCode['aircraft_typeObj'], fieldval: data.aircraft_type})],
      status:aircraftErrorCode['status'][fieldValidation({...aircraftErrorCode['statusObj'], fieldval: data.status})],
      date_of_manufacture:aircraftErrorCode['date_of_manufacture'][fieldValidation({...aircraftErrorCode['date_of_manufactureObj'], fieldval: data.date_of_manufacture})],
      portfolio: data.ownership_type === 2 ? data.owner_portfolio ? '': 'Please select Portfolio' : '',
      lessee_id: data.status && data.status.value == 1 ? aircraftErrorCode['lessee_id'][fieldValidation({...aircraftErrorCode['lessee_idObj'], fieldval: data.lessee_id})] : '',
      operator_id:data.same_operator_lessee ? '':aircraftErrorCode['operator_id'][fieldValidation({...aircraftErrorCode['operator_idObj'], fieldval: data.operator_id})],
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      let payload = Object.assign({}, data);
      payload = {
        ...payload,
        aircraft_type: data.aircraft_type.id,
        credit_classification: data.credit_classification && data.credit_classification.value ? data.credit_classification.value:null,
        maintenance_event_program_group:data.maintenance_event_program_group && data.maintenance_event_program_group.value ? data.maintenance_event_program_group.value:null,
        status: data.status.value,
        lessee_id: data.lessee_id && data.lessee_id.id ? data.lessee_id.id:null,
        operator_id: data.operator_id && data.operator_id.id ? data.operator_id.id:null,
      }
      if(payload.ownership_type === 2){
        if(payload.owner_portfolio.id){
          payload = {
            ...payload,
            owner_portfolio:payload.owner_portfolio.id
          }
        }
      }
      this.addNewAssetApi(this.props, 'console/add-aircraft/', payload)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          trackActivity('Item Added', {
              asset_type: 'Aircraft',
              page_title: 'Asset Listing',
            })
          window.location.reload();
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
        if(checkLicenseStatus(response)){
           this.props.toggleLicenseModalFn(true, response.data.message)
         }
      })
    }else{
      this.setState({error:validationInputs});
    }
  }
  render(){
    const { formSubmitLoader, data, error } = this.state;
    const { portfolios, lessees, operators, pltConstants, aircraftTypes, addAssetModal, toggleAssetType, aircraftMntGrp } = this.props;
    const {jacLeaseStatus,defaultLeaseStatus} = leaseStatus
    return(
      <Fragment>
        <Dialog
          open={addAssetModal}
          onClose={() => {toggleAssetType()}}
          aria-labelledby="scroll-dialog-title"
          fullWidth= 'true'
        >
          <DialogTitle id="scroll-dialog-title">
            Add Aircraft
          </DialogTitle>
          <DialogContent dividers={true} className="data-upload-modal">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="msn"
                    label="MSN"
                    fullWidth
                    margin="normal"
                    value={data.msn}
                    error={error.msn ? true:false}
                    helperText={error.msn ? error.msn:''}
                    onChange={(e, value) => this.onFieldChange('msn', e.target.value)}
                    onFocus={() => this.onRestErrorKey('msn')}
                    inputProps={{ maxLength: 20}}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options = {aircraftTypes}
                    getOptionLabel={option => option.name}
                    id="aircraft_type"
                    value={data.aircraft_type}
                    onChange={(e, value) => this.onFieldChange('aircraft_type', value)}
                    renderInput={params => <TextField required error={error.aircraft_type ? true:false} helperText={error.aircraft_type ? error.aircraft_type:''} onFocus={() => this.onRestErrorKey('aircraft_type')} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="current_registration_number"
                    label="Registration"
                    fullWidth
                    margin="normal"
                    value={data.current_registration_number}
                    onChange={(e, value) => this.onFieldChange('current_registration_number', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 20}}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       required
                       margin="normal"
                       id="date_of_manufacture"
                       label="Date of Manufacture"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture
                       InputLabelProps={{shrink: true}}
                       minDate={moment().subtract(25, 'years')}
                       value={data.date_of_manufacture}
                       error={error.date_of_manufacture ? true:false}
                       helperText={error.date_of_manufacture ? error.date_of_manufacture:''}
                       onFocus={() => this.onRestErrorKey('date_of_manufacture')}
                       onChange={(data, value) => this.onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat))}
                     />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options = {aircraftMntGrp ? aircraftMntGrp : []}
                    getOptionLabel={option => option.label}
                    id="maintenance_event_program_group"
                    value={data.maintenance_event_program_group}
                    onChange={(e, value) => this.onFieldChange('maintenance_event_program_group', value)}
                    renderInput={params => <TextField {...params} label="Maintenance Program Group" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options = {pltConstants.filter(item => item.type === 'credit_classification')}
                    getOptionLabel={option => option.label}
                    id="credit_classification"
                    value={data.credit_classification}
                    onChange={(e, value) => this.onFieldChange('credit_classification', value)}
                    renderInput={params => <TextField {...params} label="Credit Classification" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options = {getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus : defaultLeaseStatus}
                    getOptionLabel={option => option.label}
                    id="status"
                    value={data.status}
                    onChange={(e, value) => this.onFieldChange('status', value)}
                    renderInput={params => <TextField required error={error.status ? true:false} helperText={error.status ? error.status:''} onFocus={() => this.onRestErrorKey('status')} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="location"
                    label="Location"
                    fullWidth
                    margin="normal"
                    value={data.location}
                    onChange={(e, value) => this.onFieldChange('location', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 50}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel className="MuiInputLabel-shrink" component="label">Ownership Status</FormLabel>
                    <RadioGroup row aria-label="position" name="ownership_type" defaultValue="top">
                      <FormControlLabel value={1} control={<Radio checked={data.ownership_type === 1} onChange={() => this.onFieldChange('ownership_type', 1)} color="primary" size="small" />} label="Owned" />
                      <FormControlLabel value={2} control={<Radio checked={data.ownership_type === 2} onChange={() => this.onFieldChange('ownership_type', 2)} color="primary" size="small" />} label="Managed" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                { data.ownership_type === 2 ?
                  <Grid item xs={12}>
                    <Autocomplete
                      options = {portfolios}
                      getOptionLabel={option => option.name}
                      id="owner_portfolio"
                      value={data.owner_portfolio}
                      onChange={(e, value) => this.onFieldChange('owner_portfolio', value)}
                      renderInput={params => <TextField error={error.portfolio ? true:false} required={true} helperText={error.portfolio ? error.portfolio:''} onFocus={() => this.onRestErrorKey('portfolio')} {...params} label="Portfolio" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>:null
                }
                <Grid item xs={12}>
                  <Autocomplete
                    options = {lessees}
                    getOptionLabel={option => option.name}
                    id="lessee_id"
                    value={data.lessee_id}
                    onChange={(e, value) => this.onFieldChange('lessee_id', value)}
                    renderInput={params => <TextField error={error.lessee_id ? true:false} required={data.status && data.status.value == 1 ? true  :false} helperText={error.lessee_id ? error.lessee_id:''} onFocus={() => this.onRestErrorKey('lessee_id')} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    disabled={data?.status?.value !=1 ? true : false}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel className="MuiInputLabel-shrink" component="label">Is Lessee also the Operator?</FormLabel>
                    <RadioGroup row aria-label="position" name="same_operator_lessee" defaultValue="top">
                      <FormControlLabel value="is_titled" control={<Radio onChange={() => {this.onFieldChange('same_operator_lessee', true); this.onRestErrorKey('operator_id')}} checked={data.same_operator_lessee} color="primary" size="small" />} label="Yes" />
                      <FormControlLabel value="is_fitted" control={<Radio onChange={() => {this.onFieldChange('same_operator_lessee', false); this.onRestErrorKey('operator_id')}} checked={!data.same_operator_lessee} color="primary" size="small" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                { !data.same_operator_lessee ?
                  <Grid item xs={12}>
                    <Autocomplete
                      options = {operators}
                      getOptionLabel={option => option.name}
                      id="operator_id"
                      value={data.operator_id}
                      onChange={(e, value) => this.onFieldChange('operator_id', value)}
                      renderInput={params => <TextField required={!data.same_operator_lessee ? true :false} error={error.operator_id ? true:false} helperText={error.operator_id ? error.operator_id:''} onFocus={() => this.onRestErrorKey('operator_id')} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>:null
                }
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => {toggleAssetType();trackActivity('Item Clicked', { page_title: 'Asset Listing', asset_type: 'Aircraft', activity_type: 'Cancel' })}}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={this.onAddNewAsset}>
              { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save' }
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}
export default withSnackbar(AddAircraft);
