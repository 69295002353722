import React, { Fragment } from 'react';
import { Paper, Grid } from '@material-ui/core'
import { LabelValueCard } from '../../../../shared_elements';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat ,prettyNumberParamsDB } from '../../../../constants';
import WorkScopeLLP from './WorkScopeLLP';
const EngineUpcomingEvent = ({workScope}) => {
  return(
    <Grid item xs={4}>
      <div className="workscope-wrapper">
        <div className="run-type">
          <h4>{workScope.run_id === 1 ? '1st ': workScope.run_id === 2 ? '2nd ':'Mature '}Run</h4>
          <Grid container spacing={2}>
            <LabelValueCard xs={12} md={6} label='Life On Wing' value={workScope.life_on_wings ? workScope.life_on_wings:'--'} />
            <LabelValueCard xs={12} md={6} label='Shop Visit Date' value={moment(workScope.LLP.eventDate).format(displayDateFormat)} />
          </Grid>
        </div>
        <div className="workscopes-block">
          <Grid container spacing={2}>
            <LabelValueCard xs={12} label='Workscope modules for shop visit' value={
              <Fragment>
                Core,
                { workScope.workscope.includes(2) ? ', LPT':''}
                { workScope.workscope.includes(3) ?', FAN':''}
              </Fragment>
              }
            />
            <LabelValueCard xs={12} label='LLPs to be replaced in shop visit' value= {
                <Fragment>
                  {workScope.llps_replaced} LLP's<br/>
                  <WorkScopeLLP llps={workScope.llps_list} />
                </Fragment>
              }
            />
          </Grid>
        </div>
        <div className="performance-block">
          <h4>Performance Restoration</h4>
          <Grid container spacing={3}>
            <LabelValueCard xs={12} md={6} label='MR Rate' value={workScope.PRSV.mr !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.PRSV.mr}</NumericLabel> : "--"} />
            <LabelValueCard xs={12} md={6} label='Shop Visit Cost' value={workScope.PRSV.eventCost ? <NumericLabel params={prettyNumberParamsDB}>{workScope.PRSV.eventCost}</NumericLabel>:'--'} />
          </Grid>
          <Grid container spacing={3}>
            <LabelValueCard xs={12} md={6} label='Funds Collected' value={workScope.PRSV.fundCollected !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.PRSV.fundCollected}</NumericLabel> : "--"} />
            <LabelValueCard xs={12} md={6} label='Funds Difference' value={workScope.PRSV.surPlus !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.PRSV.surPlus}</NumericLabel> : "--"} />
          </Grid>
        </div>
        <div className="llp-block">
          <h4>LLP Replacement</h4>
          <Grid container spacing={3}>
            <LabelValueCard xs={12} md={6} label='Bulk LLP MR Rate' value={workScope.LLP.mr !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.LLP.mr}</NumericLabel> : "--"} />
            <LabelValueCard xs={12} md={6} label='LLP Replacement Cost' value={workScope.LLP.eventCost !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.LLP.eventCost}</NumericLabel> : "--"} />
          </Grid>
          <Grid container spacing={3}>
            <LabelValueCard xs={12} md={6} label='Bulk LLP MR Funds Collected' value={workScope.LLP.fundCollected !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.LLP.fundCollected}</NumericLabel>: "--"} />
            <LabelValueCard xs={12} md={6} label='Funds Difference' value={workScope.LLP.surPlus !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.LLP.surPlus}</NumericLabel> : "--"} />
          </Grid>
        </div>
        <div className="total-cost">
          <Grid container spacing={2}>
            <LabelValueCard xs={12} md={6} label='Total Funds Collected' value={workScope.total_funds !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.total_funds}</NumericLabel> : "--"} />
            <LabelValueCard xs={12} md={6} label='Total Shop Visit Cost' value={workScope.total_sv_cost !== 0 ? <NumericLabel params={prettyNumberParamsDB}>{workScope.total_sv_cost}</NumericLabel> : "--"} />
          </Grid>
        </div>
      </div>
    </Grid>
  )
}
export default EngineUpcomingEvent;
