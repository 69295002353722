import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import config from '../../config';
import homeIcon from '../../assets/images/home_icon.png';
import dbIcon from '../../assets/images/dashbordIcon.svg';
import { checkPermission } from '../../utils';
export const MainNavLink = ({match}) =>{
  return(
    <Fragment>
      <ul className="list-unstyled" >
        <li>
          <a href={`${config.domain.subDomian}`} target="_blank">
            <img src={homeIcon} alt="Home" />
            <p>Home</p>
          </a>
        </li>
        {checkPermission('maintenance','dashboard','R') ?
          <li>
            <Link className={match.path.includes('dashboard') ? 'active':''} to="/dashboard">
              <img src={dbIcon} alt="Dashboard" />
              <p>Dashboard</p>
            </Link>
          </li>:null
        }
        <li>
          <Link className={match.path.includes('assets-listing') || match.path.includes('maintenance') ? 'active':''} to="/assets-listing">
            <img src={`${config.api.s3ImageUrl}asset_list_icon.png`} alt="Assets" />
            <p>Assets</p>
          </Link>
        </li>
      </ul>
    </Fragment>
  )
}

export default withRouter(MainNavLink);
