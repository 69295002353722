import React, { Component, Fragment } from 'react';
import { TableListComp, EmptyCollection } from '../../../shared_elements';
import { ClaimList } from '../Elements';
import { getClaimListApi } from '../apiServices';
import { STableLoader } from '../../../shared_elements/loaders';
import { claimsHd } from '../';
export default class Claims extends Component {
  constructor(props){
    super(props);

  }
  render(){
    const { claims, skeletonLoader, props } = this.props;
    return(
      <div className="maintenance-module-section-body">
        <div className="maintenance-claims">
          { skeletonLoader ? <STableLoader count={4} /> :
            <TableListComp
              heads={claimsHd}
              data={claims.map((item,index) =>
                <ClaimList
                  key={index}
                  item={item}
                  viewDetail={()=> props.history.push(`/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/${item.asset_type}/view/${item.shop_visit}`)}
                />
              )}
              noRecord={claims.length ?  null : <EmptyCollection title="No records found" />}
            />
          }
        </div>
      </div>
    )
  }
}
