import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import NumericLabel from 'react-pretty-numbers';
import {getCurrencyFormat} from '../../../../utils';
import moment from 'moment';
export default function ClaimList({item, viewDetail}){
  return(
    <TableRow hover tabIndex={-1} onClick={viewDetail}>
      <TableCell>{item.name ? item.name : '--'}</TableCell>
      <TableCell>{item.submission_date ? moment(item.submission_date).format('MMM DD, YYYY') :'--'}</TableCell>
      <TableCell>{item.claim_amount ? <NumericLabel params={getCurrencyFormat()}>{item.claim_amount}</NumericLabel> :'--'}</TableCell>
      <TableCell>{item.status.label ? item.status.label:'--'}</TableCell>
    </TableRow>
  )
}
