import React from 'react';
import {Card,Link,CardContent} from "@material-ui/core";
import config from "../../config";
import restrictIcon from "../../assets/images/restrict_icon.svg";

export default function AccessDenied() {
  return (
    <div className="access-denied">
      <Card className="main">
        <CardContent>
          <img src={restrictIcon} alt="Access denied"/>
          <h2>Access denied</h2>
          <p>Click Here to Redirect to
            <Link href={config.domain.subDomian}> Home</Link>.
          </p>
        </CardContent>
      </Card>
    </div>
  );
}
