import React, { Fragment } from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { LabelValueCard } from '../../../shared_elements';
import NumericLabel from 'react-pretty-numbers';
import { permissionCheckFn } from '../../../utils';
import AddEditShopVisit from './AddEditShopVisit';
import RejectionDialog from './RejectionDialog';
import {prettyNumberParamsDB} from "../../../constants";
import { trackActivity } from '../../../utils/mixpanel';
import moment from 'moment';
export default function ShopVisitCard({item, assemblyType, mntEvents, toggleModalFn, changeSVStatus, getResponseBack, rejectionFn, buttonText, eventId, eventName}){
  return(
    <Paper elevation={3} className="mnt-event-card">
      { permissionCheckFn( {secondaryKey:'event_history', keyIndex:'U' }) && item.status && [0, 1, 2].includes(item.status.value) ?
        <span className="edit-sv">
          <AddEditShopVisit pageType="Add Shop Visit" id={item.id} shopvisitData={item} mntEvents={mntEvents} getResponseBack={getResponseBack} assemblyType={assemblyType} buttonText={buttonText} mode="edit" eventId={eventId} eventName={eventName} />
        </span>:null
      }
      <div className="content-info">
        {item.status && [0, 1, 2].includes(item.status.value) ?
          <table>
            <tbody>
              <tr>
                <td>Event {item.status.value === 2 ? 'In-Review': ' Details Submitted'}</td>
                { item.status.value === 1 && permissionCheckFn({secondaryKey:'event_history', keyIndex:'U'}) ?
                  <td>
                    <Button 
                      color="primary" 
                      variant="outlined" 
                      size='small' 
                      onClick={() => {changeSVStatus({status: item.status.value === 1 ? 2 : 3 });trackActivity('Item Clicked', { page_title: 'Claim', item_type: item.status.value === 2 ? 'Approve' : 'Move to Review', status_type: item.status.label, assembly_type: assemblyType })}}>
                        Move to Review
                    </Button>
                  </td>:null
                }
                {item.status.value === 2 && permissionCheckFn( {secondaryKey:'event_history', keyIndex:'APR_REJ' }) ?
                  <>
                    <td>
                      <Button 
                        disabled={item.status.value === 2 && !permissionCheckFn({secondaryKey:'event_history', keyIndex:'APR_REJ'})}
                        color="primary" 
                        variant="outlined" 
                        size='small' 
                        onClick={() => {changeSVStatus({status: item.status.value === 1 ? 2 : 3 });trackActivity('Item Clicked', { page_title: 'Claim', item_type: item.status.value === 2 ? 'Approve' : 'Move to Review', status_type: item.status.label, assembly_type: assemblyType })}}>
                          Approve
                      </Button>
                    </td>
                    <td><RejectionDialog pageTitle='Shop Visit' item={item} getResponseBack={getResponseBack} changeStatus={changeSVStatus} assemblyType={assemblyType} rejectionFn={rejectionFn}  /></td>
                  </>:null
                }
              </tr>
            </tbody>
          </table>:null
        }
        <Grid container spacing={3} style={{margin: 0}}>
          <LabelValueCard md={3} label='Event Number' value={item.shop_visit_number ? item.shop_visit_number:'--'} />
          <LabelValueCard md={3} label='Event Facility' value={item.shop_visit_facility ? item.shop_visit_facility:'--'} />
          <LabelValueCard md={3} label='Facility Location' value={item.facility_location ? item.facility_location:'--'} />
          <LabelValueCard md={3} label='Event Cost' value={item.cost ?  <NumericLabel params={prettyNumberParamsDB}>{item.cost}</NumericLabel>  :'--'} />
          <LabelValueCard md={3} label= {item?.asset_type == 1 ? 'Event Start Date': 'Shop Visit Start Date'} value={item.shop_visit_start_date ?  moment(item?.shop_visit_start_date).format('MMM DD, YYYY')  :'--'} />
          <LabelValueCard md={3} label= {item?.asset_type == 1 ? 'Event Completion Date': 'Shop Visit Completion Date'} value={item.shop_visit_date ?   moment(item?.shop_visit_date).format('MMM DD, YYYY')  :'--'} />
          <LabelValueCard md={6} label='Event Reason' value={item.removal_reason ? item.removal_reason:'--'} />
          <LabelValueCard md={6} label={`Event Workscope / Details`} value={item.workscope_details ? item.workscope_details:'--'} />
          <LabelValueCard md={3} label='TSN at Event' value={item.tsn ? item.tsn:'--'} />
          <LabelValueCard md={3} label='CSN at Event' value={item.csn ? item.csn:'--'} />
          <LabelValueCard md={3} label='Status' value={item.status ? item.status.label:'--'} />
          { item.status && item.status.label === 'Rejected' ?
            <LabelValueCard md={3} label='Rejection Reason' value={item.reason_for_rejection ? item.reason_for_rejection:'--'} />:null
          }
        </Grid>
      </div>
    </Paper>
  )
}
