import { globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
export function toggleAfShopVisitStatusApi(props={}, data={}){
  globalPostService(`maintenance/shopvisit/${props.match.params.id}/update-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        event: response.data.data
      }));
      // this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function toggleAfClaimStatusApi(props={}, id, data={}){
  globalPostService(`maintenance/claim/${id}/update-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        claims: prevState.claims.map(claim => claim.id === id ? response.data.data:claim),
      }));
      // this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getMntAfEventsApi(props={}, query){
  globalGetService(`maintenance/asset-type/airframe/asset/${props.match.params.assetSlug}/events/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        mntEvents:response.data.data
      }))
    }
  })
}

export function getAssemblyUpcomingEventListApi(props={}, assemblyType=""){
  this.setState({ pageLoader:true })
  globalGetService(`maintenance/asset-type/${assemblyType}/asset/${props.match.params.assetSlug}/new-upcoming-events/`)
  .then(response => {
    this.setState({ pageLoader:false })
    if(checkApiStatus(response)){
      this.setState({
        upcomingEvents: response.data.data.upcoming_events
      })
    }
  })
}
