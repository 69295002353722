import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { errorCodeReasonForRejection } from '..';
import { checkApiStatus } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { trackActivity } from '../../../utils/mixpanel';
class RejectionDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      shopVisit:{},
      error:{}

    }
  }
  updateErrorField = (key, message) => {
    this.setState(prevState =>({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      shopVisit:{
        ...prevState.shopVisit,
        [keyParam]: value
      }
    }))
  }
  addReason = () => {
    const { shopVisit } = this.state
    const { assemblyType} = this.props
     let validationInputs = {
      reason_for_rejection:errorCodeReasonForRejection['reason_for_rejection'][fieldValidation({...errorCodeReasonForRejection['reason_for_rejectionObj'], fieldval: shopVisit.reason_for_rejection})],
    }
    if(Object.keys(validationInputs).every((k)=> {return validationInputs[k] === ''})){
        this.props.rejectionFn(shopVisit)
        .then(response => {
          if(checkApiStatus(response)){
            this.props.enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{vertical:'top', horizontal:"right"}})
            this.setState({modal: false, error:{}, shopVisit:{}})
            this.props.changeStatus({status: 4})
            trackActivity('Added Rejection Reason', {
              page_title: this.props.pageTitle,
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
            })
          } else {
            this.props.enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{vertical:'top', horizontal:"right"}})
          }
        }
      )
    } else {
      this.setState({ error: validationInputs })
    }
  }
  render(){
    const { item }  = this.props
    const {modal, error, shopVisit} = this.state
    return(
      <Fragment>
        <Button onClick={()=> this.setState({modal:true,shopVisit:item}, () => trackActivity('Item Clicked', { page_title: this.props.pageTitle, item_type: 'Reject', assembly_type: this.props.assemblyType })) } color="secondary" variant="outlined" size='small'>Reject</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              Reason For Rejection
            </DialogTitle>
            <DialogContent dividers={true} style={{minWidth: '400px'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="reason_for_rejection"
                    fullWidth
                    margin="normal"
                    multiline
                    rows="4"
                    value={shopVisit.reason_for_rejection}
                    onFocus={()=>this.updateErrorField('reason_for_rejection', '')}
                    onChange={(e) => this.onFieldChange('reason_for_rejection', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    error={error.reason_for_rejection ? true: false}
                    helperText={error.reason_for_rejection ? error.reason_for_rejection : ''}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=> this.setState({modal:false}, () => trackActivity('Item Clicked', { page_title: this.props.pageTitle, item_type: 'Rejection Modal', assembly_type: this.props.assemblyType }))} color="primary">Cancel</Button>
              <Button variant="contained" onClick={this.addReason} color="primary">Save</Button>
            </DialogActions>
          </Dialog>: null
        }
      </Fragment>

    )
  }
}
export default withSnackbar(withRouter(RejectionDialog));
