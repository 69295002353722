import React, { Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import MntSideNav from './secondary_sidenav/MntSideNav';
export default function MobileSecMenu({extraProps}) {
  const [state, setState] = React.useState({left: false});
  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };
  if(['Maintenance'].includes(extraProps.apps)){
    return(
      <Fragment>
        <div className="mobile-secondary-drawer">
          <FormatIndentIncreaseIcon
            onClick={toggleDrawer('left', true)}
          />
        </div>
        <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
          <div className="mobile-main-navs mobile-secondary-manulist" style={{width: '250px'}}>
            {(() => {
              if(extraProps.apps === 'Maintenance'){
                return <MntSideNav />
              }
            })()}
          </div>
        </Drawer>
      </Fragment>
    )
  }else{
    return null
  }

}
