import React, { Component, Fragment } from 'react';
import { Button, Grid, TextField } from '@material-ui/core'
import EmptyCollection from './EmptyCollection';
import { imgStoragePath } from '../../constants';
import { trackActivity } from '../../utils/mixpanel';
import Delete from '@material-ui/icons/Delete';
class WorksScopeTable extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { workScopes, flag } = this.props
    return (
      <div className="engine-workscope-module-table">
        {flag ?
          <div className="text-right" style={{ margin: '0 0 20px 0' }}>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              disableElevation={true}
              onClick={() => {this.props.addWorkscopeModuleFn();trackActivity('Item Clicked', { page_title: 'Engine Workscope', item_type: 'Add', assembly_type: this.props.assemblyType })}}
            >+ Add Workscope</Button>
          </div> : null
        }

        <div>
          <Grid container spacing={0} className="workscope-table-heading">
            <Grid item xs={2} className="flex-centered border-right border-left">
              <h6><span>Major Module</span></h6>
            </Grid>
            <Grid item xs={10} className="flex-centered padding-0">
              <Grid container spacing={0} className="workscope-sub-row margin-0">
                <Grid item xs={3} className="submodule-col flex-centered border-right">
                  <h6>Sub Module</h6>
                </Grid>
                <Grid item xs={9} className="flex-centered padding-right-0">
                  <Grid container spacing={0} className="workscope-sub-row flex-not-centered margin-0">
                    <Grid item xs={3} className="item-col flex-centered border-right">
                      <h6>Module No</h6>
                    </Grid>
                    <Grid item xs={4} className="item-col flex-centered border-right">
                      <h6>Module Description</h6>
                    </Grid>
                    <Grid item xs={4} className="item-col workscope-level border-right">
                      <h6 className="text-center">Workscope Level</h6>
                      <Grid container spacing={0}>
                        <Grid item xs={6} className="item-col border-right">
                          <h6>Initial</h6>
                        </Grid>
                        <Grid item xs={6} className="item-col">
                          <h6>Final</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    {flag ?
                      <Grid item xs={1} className="border-right"></Grid> : null
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="engine-workscope-table">
          {workScopes.length ? workScopes.map((workScope, index) =>
            <Grid container spacing={0} className="workscope-row">
              <Grid item xs={2} className="module-col border-left border-right">
                <div className="module-content">
                  {flag ?
                    <Fragment>
                      <TextField
                        id={`name-${index}`}
                        fullWidth
                        margin="normal"
                        value={workScope.name}
                        inputProps={{ maxLength: 100 }}
                        multiline
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.props.updateWorkScope(e, 'name', e.target.value, 'majorModule', index)}
                      />
                    <span style={{cursor: 'pointer'}} onClick={() => {this.props.addSBWorkscopeModuleFn(index);trackActivity('Item Clicked', { page_title: 'Engine Workscope', item_type: 'Add Sub Module', assembly_type: this.props.assemblyType })}} className="add-new-row">+ Add a Sub Module</span>
                    </Fragment> :  workScope.name ? workScope.name : '--'
                  }
                </div>
              </Grid>
              <Grid item xs={10} className="padding-0">
                {workScope.subModule.map((sb, childIndex) =>
                  <Grid container spacing={0} className='workscope-sub-row margin-0'>
                    <Grid item xs={3} className="submodule-col border-right">
                      <div className="submodule-content">
                        {flag ?
                          <TextField
                            id={`sb-name-${childIndex}`}
                            fullWidth
                            margin="normal"
                            value={sb.name}
                            inputProps={{ maxLength: 100 }}
                            multiline
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => this.props.updateWorkScope(e, 'name', e.target.value, 'childModule', index, childIndex)}
                          /> : sb.name ? sb.name : '--'
                        }
                      </div>
                    </Grid>
                    <Grid item xs={9} className="padding-right-0">
                      {sb.items.map((item, itemIndex) =>
                        <div>
                          <Grid container spacing={0} className="workscope-item-row flex-not-centered margin-0">
                            <Grid item xs={3} className="item-col flex-centered border-right" >
                              <div className="item-content">
                                {flag ?
                                  <Fragment>
                                    <TextField
                                      id={`item-name-${itemIndex}`}
                                      fullWidth
                                      margin="normal"
                                      value={item.moduleNo}
                                      inputProps={{ maxLength: 100 }}
                                      multiline
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) => this.props.updateWorkScope(e, 'moduleNo', e.target.value, 'itemModule', index, childIndex, itemIndex)}
                                    />
                                    {(sb.items.length - 1) == itemIndex ?
                                      <span onClick={() => {this.props.addWorkScopeItem(index, childIndex);trackActivity('Item Clicked', { page_title: 'Engine Workscope', item_type: 'Add Another', assembly_type: this.props.assemblyType })}} className="add-new-row">+Add Another</span>:null
                                    }
                                  </Fragment> : item.moduleNo ? item.moduleNo : '--'
                                }
                              </div>
                            </Grid>
                            <Grid item xs={4} className="item-col flex-centered border-right">
                              <div className="item-content">
                                {flag ?
                                  <TextField
                                    id={`item-descriptiom-${itemIndex}`}
                                    fullWidth
                                    margin="normal"
                                    value={item.moduleDescription}
                                    inputProps={{ maxLength: 100 }}
                                    multiline
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => this.props.updateWorkScope(e, 'moduleDescription', e.target.value, 'itemModule', index, childIndex, itemIndex)}
                                  /> : item.moduleDescription ? item.moduleDescription : '--'
                                }
                              </div>
                            </Grid>
                            <Grid item xs={4} className="item-col workscope-level border-right flex-not-centered">
                              <Grid container spacing={0}>
                                <Grid item xs={6} className="border-right flex-centered">
                                  <div className="item-content">
                                    {flag ?
                                      <TextField
                                        id={`item-initial-${itemIndex}`}
                                        fullWidth
                                        margin="normal"
                                        value={item.initialLevel}
                                        inputProps={{ maxLength: 100 }}
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => this.props.updateWorkScope(e, 'initialLevel', e.target.value, 'itemModule', index, childIndex, itemIndex)}
                                      /> : item.initialLevel ? item.initialLevel : '--'
                                    }
                                  </div>
                                </Grid>
                                <Grid item xs={6} className="flex-centered">
                                  <div class="item-content">
                                    {flag ?
                                      <TextField
                                        id={`item-final-${itemIndex}`}
                                        fullWidth
                                        margin="normal"
                                        value={item.finalLevel}
                                        inputProps={{ maxLength: 100 }}
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => this.props.updateWorkScope(e, 'finalLevel', e.target.value, 'itemModule', index, childIndex, itemIndex)}
                                      /> : item.finalLevel ? item.finalLevel : '--'
                                    }
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            {flag ?
                              <Grid item xs={1} className="flex-centered">
                                <Delete color='secondary' onClick={() => {this.props.delWorkScopeItem(index, childIndex,itemIndex);trackActivity('Item Clicked', { page_title: 'Engine Workscope', item_type: 'Delete', assembly_type: this.props.assemblyType })}} className="del-row" style={{margin:'auto', cursor:'pointer'}} />
                              </Grid> : null
                            }
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : <EmptyCollection title="No Records Found" />
          }
        </div>
      </div>
    )
  }
}

export default WorksScopeTable;
