import React from 'react';
const NoUpcomingEvents = ({}) => {
  return(
    <div className="no-upcoming-events-card">
      <div className="upcoming-events-card-body">
        <h4>CAUTION. There are important data points missing. Please update the following fields in the "Technical Specifications":</h4>
        <ul className="list-unstyled">
          <li>Date of Manufacture</li>
          <li>Time Since New (TSN)</li>
          <li>Cycles Since New (CSN)</li>
          <li>Average Monthly Hours</li>
          <li>Average Monthly Cycles</li>
          <li>Region Of Operation</li>
          <li>TSN / CSN of asset at last Shop Visit</li>
        </ul>
      </div>
    </div>
  )
}
export default NoUpcomingEvents;
