import { globalGetService, globalExportService} from '../../utils/globalApiServices';
import { checkApiStatus ,downloadFileType} from '../../utils';

export function getAssetWidgetsApi(queryParam ={}){
  globalGetService(`/console/dashboard/asset-details/`,queryParam)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({assetData:response.data.data.asset})
      this.setState({assetFlag:true})
    }
  })
}
export function getClaimListApi(){
  globalGetService(`/maintenance/dashboard/claims-tracker/`,{})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({claimsData:response.data.data})
      this.setState({claimsFlag:true})
    }
  })
}
export function getUpcomingEventsApi(){
  // globalGetService('/maintenance/dashboard/upcoming-events/',{})
  // .then(response => {
  //   if(checkApiStatus(response)){
  //     this.setState({upcomingEventsData:response.data.data})
  //     this.setState({upcomingEventsFlag:true})
  //   }
  // })
}
export function exportUpcomingEvents(query = "pdf"){
  // this.setState({upcomingEventsFlag:true});
  // this.setState({pageLoaderFlag : true});
  // globalExportService('/maintenance/dashboard/upcoming-events/?download=pdf',{download:query.extension})
  // .then(response => {
  //   this.setState({pageLoaderFlag:false,query:{},type:''});
  //   downloadFileType(response,('upcoming_events'),query.extension);
  // })
}
