import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
class ExportReport extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <section className="export-report-section">
                <h3 className="export-report-heading">Export Report</h3>
                <p className="export-report-sub-heading">Enables user to select multiple assets (maximum 10) for downloading SPARTA reports, by a system sent link to the registered email ID</p>
            </section>
        )
    }
}
export default ExportReport;