import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, IconButton} from '@material-ui/core';
import CkEditorText from './CkEditorText'
import { DropzoneArea } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { withRouter } from 'react-router-dom';
import { getReportAnIssueConstantsApi, submitAnIssueApi } from '../apiServices';
import { fieldValidation } from '../../utils/formValidation';
const errorCode = {
  title: {
    0: "",
    1: "Please enter Title",
  },
  titleObj: {
    required: true,
  },
  moduleObj: {
    required: true,
  },
  severity: {
    0: "",
    1: "Please select Severity",
  },
  severityObj: {
    required: true,
  },
  classification: {
    0: "",
    1: "Please select Classification",
  },
  classificationObj: {
    required: true,
  },
};
const issue = {
  title: "",
  description: "",
  module: 3,
  severity: null,
  classification: null,
  attachments: [],
};
class ReportAnIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loader: false,
      error: {},
      issueInfo: {module:3},
      pltConstants: [],
    };
    this.getReportAnIssueConstantsApi = getReportAnIssueConstantsApi.bind(this);
    this.submitAnIssueApi = submitAnIssueApi.bind(this);
  }
  updateCkEditer = (evt) =>{
    var newContent = evt.editor.getData();
    this.onFieldChange(evt,'description', newContent)
}
  onFieldChange = (e, keyParam, value) => {
    this.setState((prevState) => ({
      ...prevState,
      issueInfo: {
        ...prevState.issueInfo,
        [keyParam]: value,
      },
    }));
  };
  updateErrorField = (key, message) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message,
      },
    }));
  };
  reportAnIssueFn = () => {
    const { issueInfo } = this.state;
    let validationInputs = {
      title:
        errorCode["title"][
          fieldValidation({
            ...errorCode["titleObj"],
            fieldval: issueInfo.title,
          })
        ],
      severity:
        errorCode["severity"][
          fieldValidation({
            ...errorCode["severityObj"],
              fieldval: issueInfo.severity,
          })
        ],
      classification:
        errorCode["classification"][
          fieldValidation({
            ...errorCode["classificationObj"],
            fieldval: issueInfo.classification,
          })
        ],
    };
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === "";
      })
    ) {
      this.submitAnIssueApi(this.props, issueInfo);
    } else {
      this.setState({ error: validationInputs });
    }
  };
  render() {

    const { modal, issueInfo, error, pltConstants } = this.state;
    const { type } = this.props;
    return (
      <Fragment>
        {type === "head" ? (
          <Tooltip title="Report an Issue" arrow>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                this.setState({ modal: true, issueInfo: issue });
                this.getReportAnIssueConstantsApi(this.props, {
                  constant_types: [
                    "bug_classification",
                    "bug_severity",
                    "bug_module",
                  ],
                });
              }}
            >
              <HeadsetMicIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <span
            style={{
              position: "fixed",
              bottom: "22px",
              right: "65px",
              background: "#2f8cfe",
              padding: "3px 5px",
              borderRadius: "2px",
              fontSize: "11px",
              color: "#fff",
              display: "inline-block",
              zIndex: 10,
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 6px",
            }}
            onClick={() => {
              this.setState({ modal: true, issueInfo: issue });
              this.getReportAnIssueConstantsApi(this.props, {
                constant_types: [
                  "bug_classification",
                  "bug_severity",
                  "bug_module",
                ],
              });
            }}
          >
            Report an Issue
          </span>
        )}

        {modal ? (
          <Dialog
            open={modal}
            onClose={() => this.setState({ modal: false, issueInfo: {} })}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title"> Issue</DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="title"
                    label="Title"
                    fullWidth
                    margin="normal"
                    value={issueInfo.title ? issueInfo.title : ""}
                    error={error.title ? true : false}
                    helperText={error.title ? error.title : ""}
                    onFocus={() => this.updateErrorField("title", "")}
                    onChange={(e) =>
                      this.onFieldChange(e, "title", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.54)",
                      marginBottom: "4px",
                    }}
                  >
                    Description
                  </p>
                  <div style={{ position: "relative", zIndex: "99" }}>
                    <CkEditorText
                      htmlData={''}
                      onChangeData={(data) => this.onFieldChange('', 'description', data)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={ pltConstants.filter(
                      (item) =>item.type === "bug_module")}
                    getOptionLabel={(option) => option.label}
                    id="module"
                    value={{value: 3, label: 'Maintenance'}}
                    disabled={true}
                    onChange={(e, value) =>
                      this.onFieldChange(
                        e,
                        "module",
                        value ? value.value : value
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        error={error.module ? true : false}
                        helperText={error.module ? error.module : ""}
                        onFocus={() => this.updateErrorField("module", "")}
                        {...params}
                        label="Module"
                        value="Maintenance"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={pltConstants.filter(
                      (item) => item.type === "bug_severity"
                    )}
                    getOptionLabel={(option) => option.label}
                    id="severity"
                    value={
                      pltConstants.length && issueInfo.module
                        ? pltConstants.find(
                            (item) =>
                              item.type === "bug_severity" &&
                              item.value === issueInfo.severity
                          )
                        : null
                    }
                    onChange={(e, value) =>
                      this.onFieldChange(
                        e,
                        "severity",
                        value ? value.value : value
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        error={error.severity ? true : false}
                        helperText={error.severity ? error.severity : ""}
                        onFocus={() => this.updateErrorField("severity", "")}
                        {...params}
                        label="Severity"
                        placeholder="Select Severity"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={pltConstants.filter(
                      (item) => item.type === "bug_classification"
                    )}
                    getOptionLabel={(option) => option.label}
                    id="classification"
                    value={
                      pltConstants.length && issueInfo.classification
                        ? pltConstants.find(
                            (item) =>
                              item.type === "bug_classification" &&
                              item.value === issueInfo.classification
                          )
                        : null
                    }
                    onChange={(e, value) =>
                      this.onFieldChange(
                        e,
                        "classification",
                        value ? value.value : value
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        error={error.classification ? true : false}
                        helperText={
                          error.classification ? error.classification : ""
                        }
                        onFocus={() =>
                          this.updateErrorField("classification", "")
                        }
                        {...params}
                        label="Classification"
                        placeholder="Select Classification"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    className=""
                    style={{
                      display: "inline-block",
                      width: "300px",
                      position: "relative",
                    }}
                  >
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={["image/jpg", "image/jpeg", "image/png"]}
                      showPreviewsInDropzone={false}
                      showPreviews={true}
                      dropzoneText={
                        <p>
                          Drag & Drop Pictures
                          <br /> OR <br /> Click Here
                        </p>
                      }
                      dropzoneClass="drag-drop-cnt"
                      maxWidth="sm"
                      showAlerts={["error", "info"]}
                      onChange={(files) =>
                        this.onFieldChange("", "attachments", files)
                      }
                      alertSnackbarProps={{
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                      }}
                    />
                    <p className="file-upload-note">
                      Supported Extension .jpg, jpeg, .png
                    </p>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => this.setState({ modal: false, issueInfo: {} })}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={this.reportAnIssueFn}
                variant="contained"
                disabled={this.state.loader}
              >
                {this.state.loader ? 'Processing' : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Fragment>
    );
  }
}
export default withSnackbar(ReportAnIssue);
