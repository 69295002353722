import React from 'react';
import { Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import {displayDateFormatShort} from '../../../constants';
const ShopVisitTitle = ({event, assemblyType, goBack}) => {
  return (
    <Grid container spacing={0} className='title-info'>
      <Grid xs={8} >
        <ArrowBackIcon color="primary" className="arrow-icon" onClick={goBack}/>
        <h4>{event.name}</h4>
        <p style={isMobile ? {fontSize:"12px"} : {}}>{assemblyType=='airframe'? 'Event': 'Shop Visit'} Date : {moment(event.shop_visit_date).format(displayDateFormatShort)} </p>
      </Grid>
    </Grid>
  )
}
export default ShopVisitTitle;
