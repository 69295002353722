import React, {Component, Fragment} from 'react';
import { withRouter} from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import { errorCodeClaim, claimObj } from '..';
import { regexConstants} from '../../../constants/regEx';
import {addEditAssemblyClaimApi} from '../apiServices';
import {checkApiStatus} from '../../../utils';
import EditIcon from '@material-ui/icons/Edit';
import { trackActivity } from '../../../utils/mixpanel';
class AddEditClaim extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      mode:'',
      claim: {},
      error:{},
      heading:''
    }
    this.addEditAssemblyClaimApi = addEditAssemblyClaimApi.bind(this)
  }
  componentDidMount(){
    this.setState({
      claim: this.props.claimObj
    })
  }
  updateErrorField = (key, message) => {
    this.setState(prevState =>({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      claim: {
        ...prevState.claim,
        [keyParam]: data
      }
    }))
  }
  addEditClaim = () => {
    const {claim} = this.state
    let validationInputs = {
      claim_amount:errorCodeClaim['claim_amount'][fieldValidation({...errorCodeClaim['claim_amountObj'], fieldval:claim.claim_amount})],
      submission_date:errorCodeClaim['submission_date'][fieldValidation({...errorCodeClaim['submission_dateObj'], fieldval:claim.submission_date})]
    }
    if(Object.keys(validationInputs).every((k)=>{return validationInputs[k] === ''})){
      this.addEditAssemblyClaimApi(this.props, claim )
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant:'success',anchorOrigin:{vertical:"top",horizontal:"right"}});
          this.setState({modal:false, mode:'', claim:{}, error:{}});
          this.props.getResponseBack();
          trackActivity('Item Added / Edited', {
              page_type: 'Claims',
              page_title: 'Shop visit',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
            })
        } else {
          this.props.enqueueSnackbar(response.data.message, {variant:'error',anchorOrigin:{vertical:'top', horizontal:"right"}})
        }
      })
    }else{
      this.setState({error: validationInputs})
    }

  }
  render() {
    const { claim, error, modal, heading, mode} =this.state
    const { id, claimData, buttonText, shopVisitId, size, variant } = this.props
    return (
      <Fragment>
        { id ? <EditIcon onClick={() => this.setState({modal:true, mode:'edit',heading:'Edit Claim' ,claim:claimData, error:{}}, () => trackActivity('Item Clicked', { page_title: 'Shop visit', item_type: 'edit', assembly_type: this.props.assemblyType }))} color="primary"  /> :
           <Button size={size} className="add-mr-claim" color="primary" variant={variant ? variant : "contained"} onClick={() => this.setState({modal:true, mode:'add', heading:'Add Claim',claim:{...claimObj, shop_visit_id:parseInt(shopVisitId)}, error:{}}, () => trackActivity('Item Clicked', { item_type: `${mode} ${buttonText}`}))}> + {buttonText}</Button>
         }
        { modal ?
          <Dialog
            open={modal}
            onClose={()=>{this.setState({modal:false, error:{},claim:{}})}}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {heading}
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       required
                       margin="normal"
                       id="start_date"
                       label="Submission Date"
                       format={fieldDateFormat}
                       fullWidth
                       InputLabelProps={{shrink: true}}
                       value={claim.submission_date ? moment(claim.submission_date):null}
                       onChange={(data, value) => this.onFieldChange(value, 'submission_date', data ? moment(data).format(backendDateFormat):data)}
                       onFocus={() => this.updateErrorField('submission_date', '')}
                       error={error.submission_date ? true: false}
                       helperText={error.submission_date ? error.submission_date :''}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="name"
                    label="Claim Amount"
                    fullWidth
                    margin="normal"
                    value={claim.claim_amount}
                    onChange={(e, value) =>  { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'claim_amount', e.target.value): e.preventDefault()}}
                    onFocus={()=> this.updateErrorField('claim_amount', '')}
                    error={error.claim_amount ? true:false }
                    helperText={error.claim_amount ? error.claim_amount : ''}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Claim Details"
                    fullWidth
                    margin="normal"
                    multiline
                    rows="4"
                    value={claim.description}
                    onChange={(e, value) => this.onFieldChange(e, 'description', e.target.value)}
                    error={error.name ? true:false }
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Remarks"
                    fullWidth
                    margin="normal"
                    multiline
                    rows="4"
                    value={claim.remarks}
                    onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                    error={error.name ? true:false }
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=> this.setState({ modal:false, error:{},claim:{}}, () => trackActivity('Item Clicked', { page_title: 'Shop Visit', page_type: 'Claims', activity_type: 'Cancel' }))} color="primary">Cancel</Button>
              <Button variant="contained" onClick={this.addEditClaim} color="primary">Save</Button>
            </DialogActions>
          </Dialog> : null
        }

      </Fragment>

    )
  }

}

export default withSnackbar(withRouter(AddEditClaim));
