import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogContent, } from '@material-ui/core';
const LicenseFailModal = ({licenseModalMessage, licenseModal, toggleModalFn}) => {
  return(
    <Dialog
      open={licenseModal}
      aria-labelledby="scroll-dialog-title"
      onClose={toggleModalFn}
      classes={{paperScrollPaper: "license-modal"}}
      maxWidth="lg"
    >
      <DialogContent dividers={true}>
        <div className="licence-description">
          <p align="center" style={{marginBottom:'20px', marginTop:'20px'}}>{licenseModalMessage}</p>
          <p align="center" style={{marginBottom:'20px'}}>Please contact
            <a href="mailto:sales@acumen.aero" target="_blank" rel="noreferrer"> sales@acumen.aero</a> /
            <a href="mailto:support@sparta.aero" target="_blank" rel="noreferrer"> support@sparta.aero</a>
          </p>
        </div>
        <div align="center" style={{marginBottom:'20px'}}>
        <Button color="primary" variant="contained" onClick={toggleModalFn}>OKAY</Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default withRouter(LicenseFailModal);
