import {Component} from "react";
import {Grid,Paper,Accordion,AccordionSummary,AccordionDetails,Typography,Link,makeStyles} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat ,prettyNumberParamsDB } from '../../../constants';
import EventCard from './EventCard';
const GenericUpcomingEvents = ({title,type, data}) => {
  return (
    <Grid item xs={3} key={title}>
      <Paper className="event-component">
        <h2>{title}</h2>
        <Grid container className="event-list">
        { data.map((event,index) => <EventCard event={event} key={index} type={type} />)}
        </Grid>
      </Paper>
    </Grid>
  );
}
export default GenericUpcomingEvents;
