import React, {  Component } from 'react';
import { Link } from 'react-router-dom'
import { permissionCheckFn } from '../utils';
export default function userRoleBaseAccessHoc(HocComponent, extraProps=[]){
  return class extends Component{
    render(){
      if(permissionCheckFn({primaryKey: extraProps[0],secondaryKey:extraProps[1], keyIndex:extraProps[2]})){
        return (
          <HocComponent {...this.props} />
        )
      }else{
        return(
          <div style={{width:'100wh', height:'100vh', position:'relative'}}>
            <div style={{position: 'fixed', top: '50%', left: '58%', transform: 'translate(-50%, -58%)', color: '#000000', textAlign: 'center'}}>
              <br/><br/>
                Sorry, You do not have access to the Page.<br/>
                Please contact to Acumen for access <Link to="/assets-listing">Go Back</Link>
            </div>
          </div>
        )
      }
    }
  }
}
