import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { Button, Grid } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection } from '../../../shared_elements';
import { Role, RoleDetail } from '../components';
import { getRolesApi, getRoleUsersApi } from '../apiServices';
import { rolesHd } from '../'
import { trackActivity } from '../../../utils/mixpanel';
class Roles extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:false,
      pageLoader:false,
      roleDetail:{
        modal:false,
        data:null
      },
      roleList:[]
    }
    this.getRolesApi = getRolesApi.bind(this);
    this.getRoleUsersApi = getRoleUsersApi.bind(this);
  }
  componentDidMount(){
    this.getRolesApi(this.props, 'skeletonLoader');
    trackActivity('Page Visited', {
      page_title: 'Settings(Roles)',
    })
  }
  render(){
    const { roleList, roleDetail, skeletonLoader, pageLoader } = this.state;
    return(
      <section className="roles-section">
        <div className="heading flex-centered">
          <h3>Roles</h3>
        </div>
        { skeletonLoader ? <STableLoader />:
          <TableListComp
            heads={rolesHd}
            data={ roleList.map((item,index) =>
              <Role
                item={item}
                index={index}
                onRoleDetail={() => this.getRoleUsersApi(this.props, item)}
              />
            )}
            noRecord={ roleList.length ? null:
              <EmptyCollection
                title="No Records Found"
              />
            }
          />
        }
        {roleDetail.modal ?
          <RoleDetail
            roleDetail={roleDetail}
            toggleModalFn={() => this.setState({roleDetail:{modal:false, data:null}})}
          />:null
        }
      </section>
    )
  }
}
export default withSnackbar(Roles);
