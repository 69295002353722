import { globalGetService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
export function getClaimListApi(props={}, query={}){
  this.setState({
    skeletonLoader: true
  })
  globalGetService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        claims: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
