import { Grid,Link } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import config from "../../../config"
export default function Header({
  assetWidgetCard,
  assetFlag
}) {
  return (
    <section className="Head">
      {
        assetWidgetCard.map(asset => {
          return (
            <div className={asset.classes} key={asset.title}>
              {
                assetFlag
                ?
                <>
                  <Link href={`${config.domain.subDomian + asset.url.slice(1)}`}>
                    <div className="ta-container banner">
                      <h3>{asset.title + " - " + asset.value}</h3>
                    </div>
                  </Link>
                  <div className="details-wrapper">
                      <Link href={`${config.domain.subDomian + asset.ownedUrl.slice(1)}`} className="ta-container owned">
                          <h3>{asset.owned}</h3>
                          <h3>OWNED</h3>
                      </Link>
                      <Link href={`${config.domain.subDomian + asset.managedUrl.slice(1)}`} className="ta-container managed">
                        <h3>{asset.managed}</h3>
                        <h3>MANAGED</h3>
                    </Link>
                  </div>
                </>
                :
                <div className="ta-container loader">loading...</div>
              }
            </div>
          );
        })
      }
    </section>
  );
}
