import React, {Fragment} from 'react';
import { NavLink, withRouter } from 'react-router-dom';
function MntSideNav(props){
  return(
    <Fragment>
      {
        props.match.path.includes('maintenance') ?
        <Fragment>
        <ul className="list-unstyled" style={{display: 'none'}}>
          {[
              {label: 'Claims', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/claims`},
          ].map((item, index) =>
            <li key={index}>
              <NavLink to={item.path}>{item.label}</NavLink>
            </li>
          )}
        </ul>
        <div>
          <ul className="list-unstyled">
            { props.match.params.assetType == 'aircraft' ?
              [
                  {label: 'Airframe', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/airframe`, activeStrCheck: 'airframe'},
                  {label: 'APU', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/apu`, activeStrCheck: 'apu'},
                  {label: 'Landing Gears', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/landing-gears` , activeStrCheck: 'landing-gears'},
                  {label: 'Engines', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/engines`, activeStrCheck: 'engine'}
              ].map((item, index) =>
                <li key={index}>
                  <NavLink className={window.location.href.includes(item.activeStrCheck) ? "active" : ""} to={item.path}>{item.label}</NavLink>
                </li>
              ) :
              [{label: 'Engine', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/engines`}].map((item, index) =>
                <li key={index}>
                  <NavLink className="active" to={item.path}>{item.label}</NavLink>
                </li>
              )
            }
          </ul>
        </div>
      </Fragment> : null
      }
      { props.match.path.includes('settings') ?
        <Fragment>
          <ul className="list-unstyled">
          <li>
              <NavLink to="/settings/users" className={props.match.path.includes('settings/users') ? 'active':''}> Users</NavLink>
            </li>
            <li>
              <NavLink to="/settings/roles" className={ props.match.path.includes('settings/roles') ? 'active':''}>Roles</NavLink>
            </li>
          </ul>
        </Fragment>:null
      }
    </Fragment>
  )
}

export default withRouter(MntSideNav)
