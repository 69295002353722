import React from 'react';
import ReactDOM from 'react-dom';
// import Next from 'next'
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/fonts.scss';
import './assets/styles/material_ui_override.scss';
import './assets/styles/common.scss';
import './assets/styles/layout.scss';
import './assets/styles/normalize.scss';
import './assets/styles/maintenance.scss';
import './assets/styles/auth.scss';
import './assets/styles/settings.scss';
import './assets/styles/dashboard.scss';
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
