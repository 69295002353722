import React, {Component, Fragment} from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { withSnackbar } from 'notistack';
import { capitalizeFirstLetter, checkApiStatus } from '../../../../utils';
import { regexConstants } from '../../../../constants/regEx';
import { fieldValidation } from '../../../../utils/formValidation';
import { addEditLLPApi } from '../apiServices';
import { erroCodeLLP } from '..';
import { trackActivity } from '../../../../utils/mixpanel';
class EditEngineLLP extends Component {
  constructor(props){
    super(props)
    this.state = {
      modal:false,
      LLPCru:{},
      error:{}
    }
    this.addEditLLPApi = addEditLLPApi.bind(this)
  }
  updateErrorField = (key, message) => {
    this.setState(prevState =>({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      LLPCru: {
        ...prevState.LLPCru,
        [keyParam]: data
      }
    }))
  }
  editLLP = () => {
    const { LLPCru } = this.state
    let validationInputs={
      cycles_remaining:erroCodeLLP['cycles_remaining'][fieldValidation({...erroCodeLLP['cycles_remainingObj'], fieldval: LLPCru.cycles_remaining})],
      list_price:erroCodeLLP['list_price'][fieldValidation({...erroCodeLLP['list_priceObj'], fieldval: LLPCru.list_price})],
    }
    if(Object.keys(validationInputs).every((k)=>{return validationInputs[k] === ''})){
      this.addEditLLPApi(this.props, LLPCru)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message,{ variant:'success', anchorOrigin:{vertical:'top', horizontal:"right"}})
          this.setState({modal:false, LLPCru:{}})
          this.props.getResponseBack();
          trackActivity('Item Edited', {
              page_type: 'LLP',
              page_title: 'Engine LLP',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
            })
        }else {
          this.props.enqueueSnackbar(response.data.message, { variant:"success", anchorOrigin:{vertical:'top', horizontal:'right'}})
        }
      })
    }else {
      this.setState({
        error: validationInputs
      })
    }
  }

  render() {
    const { modal, LLPCru, error } = this.state
    const {llp} = this.props
    return (
      <Fragment>
        <Tooltip title="Edit" arrow>
          <EditIcon onClick={()=> this.setState({modal: true, LLPCru:llp}, () => trackActivity('Item Clicked', { page_title: 'LLP', item_type: 'Edit', assembly_type: this.props.assemblyType }))} color="primary" style={{cursor:'pointer', verticalAlign:'sub'}}/>
        </Tooltip>
        {modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, })}
            aria-labelledby="scroll-daialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {capitalizeFirstLetter('edit LLP')}
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="cycles_remaining"
                    label="Cycles Remaining"
                    fullWidth
                    margin="normal"
                    value={LLPCru.cycles_remaining}
                    onChange={(e, value) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e,'cycles_remaining', e.target.value):e.preventDefault()}}
                    error={error.cycles_remaining ? true:false }
                    onFocus={()=>this.updateErrorField('cycles_remaining', '')}
                    InputLabelProps={{shrink: true}}
                    helperText={error.cycles_remaining ? error.cycles_remaining:''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="list_price"
                    label ='Price'
                    fullWidth
                    margin='normal'
                    value={LLPCru.list_price}
                    onChange={(e,value) => {regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e,'list_price', e.target.value):e.preventDefault()}}
                    error={error.list_price ? true: false}
                    onFocus={()=> this.updateErrorField('list_price', '')}
                    InputLabelProps={{shrink: true}}
                    helperText={error.list_price? error.list_price:''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="part_number"
                    label ='Part Number'
                    fullWidth
                    margin='normal'
                    value={LLPCru.part_number}
                    onChange={(e,value) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e,'part_number', e.target.value):e.preventDefault()}}
                    error={error.part_number ? true: false}
                    onFocus={()=> this.updateErrorField('part_number', '')}
                    InputLabelProps={{shrink: true}}
                    helperText=""
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    id="serial_number"
                    label ='Serial Number'
                    fullWidth
                    margin='normal'
                    value={LLPCru.serial_number}
                    onChange={(e,value) =>  this.onFieldChange(e,'list_price', e.target.value)}
                    error={error.serial_number ? true: false}
                    onFocus={()=> this.updateErrorField('serial_number', '')}
                    InputLabelProps={{shrink: true}}
                    helperText=""
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    id="module"
                    label ='Module'
                    fullWidth
                    margin='normal'
                    value={LLPCru.module}
                    onChange={(e,value) => this.onFieldChange(e,'list_price', e.target.value)}
                    error={error.module ? true: false}
                    onFocus={()=> this.updateErrorField('module', '')}
                    InputLabelProps={{shrink: true}}
                    helperText=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    id="description"
                    label ='Description'
                    fullWidth
                    margin='normal'
                    value={LLPCru.description}
                    onChange={(e,value) =>  this.onFieldChange(e,'list_price', e.target.value)}
                    error={error.description ? true: false}
                    onFocus={()=> this.updateErrorField('description', '')}
                    InputLabelProps={{shrink: true}}
                    helperText=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    id="csn"
                    label ='CSN'
                    fullWidth
                    margin='normal'
                    value={LLPCru.csn}
                    onChange={(e,value) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e,'csn', e.target.value):e.preventDefault()}}
                    error={error.csn ? true: false}
                    onFocus={()=> this.updateErrorField('csn', '')}
                    InputLabelProps={{shrink: true}}
                    helperText=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    id="cycles_max_limit"
                    label ='Cycle Max Limit'
                    fullWidth
                    margin='normal'
                    value={LLPCru.cycles_max_limit}
                    onChange={(e,value) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e,'cycles_max_limit', e.target.value):e.preventDefault()}}
                    error={error.cycles_max_limit ? true: false}
                    onFocus={()=> this.updateErrorField('cycles_max_limit', '')}
                    InputLabelProps={{shrink: true}}
                    helperText=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    rows={3}
                    id="remarks"
                    label ='Remarks'
                    fullWidth
                    margin='normal'
                    value={LLPCru.remarks}
                    onChange={(e,value) => this.onFieldChange(e,'remarks', e.target.value)}
                    error={error.remarks ? true: false}
                    onFocus={()=> this.updateErrorField('remarks', '')}
                    InputLabelProps={{shrink: true}}
                    helperText=""
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false, LLPCru:{}, error:{}}, () => trackActivity('Item Clicked', { page_title: ' Engine LLP', page_type: 'Edit Engine LLP', activity_type: 'Cancel', assembly_type: this.props.assemblyType }))} color="primary">Cancel</Button>
              <Button variant="contained" onClick={this.editLLP} color="primary">Save</Button>
            </DialogActions>
          </Dialog>
          :null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(EditEngineLLP));
