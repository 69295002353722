import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const  CkEditorText = ({htmlData, onChangeData, disabled=false}) => {
  return(
    <div className="ck-content">
      <CKEditor
        editor={ ClassicEditor }
        data={''}
        disabled={disabled}
        config={{removePlugins:['MediaEmbed', 'ImageUpload', 'Indent']}}
        onInit={ editor => {
            // You can store the "editor" and use when it is needed.

        }}
        onChange={ ( event, editor ) => {
            // const data = editor.getData();

        }}
        onBlur={ ( event, editor ) => {
          const data = editor.getData();
          onChangeData(data)
        }}
        onFocus={ ( event, editor ) => {

        }}
      />
    </div>
  )
}
export default CkEditorText;
